import { Box, Button, Paper } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import renderHTML from 'react-render-html';
import { Link as RouterLink } from 'react-router-dom';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DateTime } from './decorators/DateTime';
import { DNA } from './decorators/DNA';
import { Number } from './decorators/Number';
import { Status } from './decorators/Status';
import { DetailView } from './DetailView';
import EmpiricalCDF from './EmpiricalCDF';
import { FASTQFileTable } from './FASTQFileTable';
import Graph from './Graph';
import HeatmapViz from './HeatmapViz';
import Histogram from './Histogram';
import QualityGraph from './QualityGraph';
import SampleForm from './SampleForm';
import { SequenceLogo } from './SequenceLogo';
import { VariantCountTable } from './VariantCountTable';

/**
 * Component that renders a detailed view of the Sample
 */
/**
 * Required props
 * model_name: actual model name used in Django: Sample
 * model_id: uuid associated with model
 * watch_fields: fields to watch on the model
 * detail_fields: fields that should be displayed on detail panel
 */

class PureSample extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editFormOpen: false,
      model: null,
      errors: null,
      variants: null,
      enrichments: null
    };
    this.socketRef = React.createRef();
    this.model_name = 'Sample';
    this.model_rest = 'sample';
    this.watch_fields = ['name', 'status', 'reads'];
    this.model_id = this.props.sample_id;
    this.model_title = obj => obj.name;
    this.detail_fields = [
      {
        title: 'UUID',
        field: 'uuid'
      },
      {
        title: 'Name',
        field: 'name'
      },
      {
        title: 'Description',
        field: 'description',
        render: obj => obj.description != null && <Box>{renderHTML(obj.description)}</Box>
      },
      {
        title: 'Status',
        field: 'status',
        render: obj => <Status curated={false} status={obj.status} index={-1} />
      },
      {
        title: 'Reads',
        field: 'reads',
        render: obj => <Number num={obj.reads} />
      },
      {
        title: 'Starting Sample',
        field: 'starting_sample',
        render: obj => (
          <RouterLink to={`/sample/${obj.starting_sample}/`} aria-label="view">
            Link
          </RouterLink>
        )
      },
      {
        title: 'I7 Index',
        field: 'index_i7',
        render: obj => <><DNA seq={obj.index_i7} /> {"(" + util.getKeyByValue(util.NEB_indexes, obj.index_i7) + ")"} </>
      },
      {
        title: 'I5 Index',
        field: 'index_i5',
        render: obj => <><DNA seq={obj.index_i5} /> {"(" + util.getKeyByValue(util.NEB_indexes, obj.index_i5) + ")"}</>
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: obj => <DateTime timestamp={obj.time_created} />
      }
    ];
    this.handleEditFormOpen = this.handleEditFormOpen.bind(this);
    this.handleEditFormClose = this.handleEditFormClose.bind(this);
    this.handleGetModel = this.handleGetModel.bind(this);
  }
  handleEditFormOpen() {
    this.setState({
      editFormOpen: true
    });
  }

  handleEditFormClose() {
    this.setState({
      editFormOpen: false
    });
  }

  async componentDidMount() {
    await this.handleGetModel();
  }

  async handleGetModel() {
    let model = await api.get(this.model_rest, this.model_id, this.props.enqueueSnackbar);
    if (model != null) {
      this.setState({
        model: model
      });
    }
    // get variant type and reference name
    if (model != null && model.hasOwnProperty('variant_type') && model.variant_type != null) {
      var variant_type = await api.get(
        'varianttype',
        model.variant_type,
        this.props.enqueueSnackbar
      );
      var reference = await api.get('refseq', variant_type.reference, this.props.enqueueSnackbar);
    }

    this.setState({
      variant_type: variant_type,
      reference: reference
    });
  }

  handleSocketUpdate(data) {
    let merged = api.merge_update(data, this.state.model);
    this.setState({ model: merged });
  }

  handleSocketOpen(data) {
    api.send_watch(this.socketRef, this.model_name, [this.model_id], this.watch_fields);
  }

  componentWillUnmount() {
    api.disconnect_socket(this.socketRef);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          <DetailView
            model_name={this.model_name}
            model_title={this.model_title}
            detail_fields={this.detail_fields}
            model={this.state.model}
            edit={
              <Button variant="contained" onClick={this.handleEditFormOpen}>
                Edit
              </Button>
            }
          />
        </Paper>
        {this.state.model != null && (
          <>
            <Websocket
              ref={this.socketRef}
              url={common.watcher_socket_location}
              onOpen={this.handleSocketOpen.bind(this)}
              onMessage={this.handleSocketUpdate.bind(this)}
              reconnect={true}
            />
            <SampleForm
              open={this.state.editFormOpen}
              handleClose={this.handleEditFormClose}
              sample={this.state.model}
              callback={this.handleGetModel}
              mode="edit"
            />
          </>
        )}
        {this.state.model != null &&
          this.state.model.statistics != null &&
          this.state.model.statistics.hasOwnProperty('nw_graph') && (
            <Graph
              className={classes.root}
              graph={this.state.model.statistics.nw_graph}
              mode="2d"
              title={'Variant Graph'}
            />
          )}
        {this.state.model != null &&
          this.state.model.statistics != null &&
          this.state.model.statistics.hasOwnProperty('count_histogram') && (
            <EmpiricalCDF
              className={classes.root}
              bins={this.state.model.statistics.count_histogram.bins}
              freq={this.state.model.statistics.count_histogram.frequency}
              title={'Count Empirical CDF'}
              xaxis_label="count"
            />
          )}
        {this.state.model != null &&
          this.state.model.statistics != null &&
          this.state.model.statistics.hasOwnProperty('mutual_information') && (
            <HeatmapViz
              className={classes.root}
              length={7}
              data={this.state.model.statistics.mutual_information}
              title={'Mutual information'}
            />
          )}
        {this.state.model != null &&
          this.state.model.statistics != null &&
          this.state.model.statistics.hasOwnProperty('enrichment_histogram') && (
            <EmpiricalCDF
              className={classes.root}
              bins={this.state.model.statistics.enrichment_histogram.bins.slice(1)}
              freq={this.state.model.statistics.enrichment_histogram.frequency}
              title={'Enrichment Empirical CDF'}
              xaxis_type="value"
              xaxis_label="enrichment"
            />
          )}
        {this.state.model != null &&
          this.state.model.aligned &&
          this.state.variant_type != null &&
          this.state.reference != null && (
            <VariantCountTable
              reference={this.state.reference}
              variant_type={this.state.variant_type}
              sample_id={this.props.sample_id}
            />
          )}
        {this.state.model != null &&
          this.state.model.statistics != null &&
          this.state.model.statistics.hasOwnProperty('logo') && (
            <SequenceLogo
              className={classes.root}
              logo={this.state.model.statistics.logo}
              title={'Sequence Logo'}
            />
          )}
        {this.state.model != null && this.state.model.statistics != null &&
          this.state.model.statistics.hasOwnProperty('quality') && 
          this.state.model.statistics.quality != null && (
          <QualityGraph
            className={classes.root}
            qual={this.state.model.statistics.quality}
            title={'Average Sequencing Quality (per base pair)'}
          />
        )}
        {this.state.model != null && this.state.model.statistics != null && (
          <Histogram
            className={classes.root}
            hist={this.state.model.statistics.quality_sample}
            xlabel={'Quality score'}
            title={'Mean Quality Histogram'}
          />
        )}
        {this.state.model != null && this.state.model.statistics != null && (
          <Histogram
            className={classes.root}
            hist={this.state.model.statistics.gc_sample}
            xlabel={'GC content (%)'}
            title={'% GC Content Histogram'}
          />
        )}
        <FASTQFileTable sample_id={this.props.sample_id} />
      </>
    );
  }
}
const Sample = withSnackbar(withStyles(styles.detail_styles)(PureSample));

export { Sample };

