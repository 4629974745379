import { Box, Button, Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import { Download } from './decorators/Download.jsx';
import { StatusChip } from './decorators/StatusChip.jsx';
import Loading from './Loading';
import PDBViewer from './Viewer/PDBViewer.jsx';



/**
 * Component that renders a detailed view of the Rosetta Run
 */
/**
 * Required props
 * model_name: actual model name used in Django: Sample
 * model_id: uuid associated with model
 * watch_fields: fields to watch on the model
 * detail_fields: fields that should be displayed on detail panel
 */

function Log(props) {
  if (props.status.hasOwnProperty(props.opname)) {
    var op = props.status[props.opname]
    return (
      <>
        Operation: <StatusChip op={op} />
        <Box fontFamily="Monospace">
          <div style={{ overflow: "scroll", height: "300px", backgroundColor: "#000", color: "#fff", flexDirection: "column-reverse", borderRadius: "5px" }} >
            <table>
              <tbody>
                {op.log.map((line) => (<tr><td>{line}</td></tr>))}
              </tbody>
            </table>
          </div>
        </Box>
      </>
    )

  } else {
    return (
      <></>
    )
  }
}

class PureRosettaRun extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      errors: null,
      variants: null,
      editFormOpen: false
    };
    this.socketRef = React.createRef();
    this.model_name = 'RosettaRun';
    this.model_rest = 'rosettarun';
    this.model_id = this.props.rosettarun_id;
    this.model_title = obj => obj.name;
    this.detail_fields = [
      {
        title: 'UUID',
        field: 'uuid'
      }
    ];
    this.handleGetModel = this.handleGetModel.bind(this);
    this.handleDeleteRun = this.handleDeleteRun.bind(this);
    this.watch_fields = ['status'];
  }
  handleSocketUpdate(data) {
    let merged = api.merge_update(data, this.state.model);
    this.setState({ model: merged });
  }
  handleSocketOpen(data) {
    api.send_watch(this.socketRef, this.model_name, [this.model_id], this.watch_fields);
  }

  async componentDidMount() {
    if (this.props.model == null) {

      await this.handleGetModel();
    } else {
      this.setState({
        model: this.props.model

      }
      )
    }
  }

  componentWillUnmount() {
    api.disconnect_socket(this.socketRef);
  }

  async handleGetModel() {
    var model = await api.get(this.model_rest, this.model_id);
    if (model != null) {
      this.setState({
        model
      });
    }
  }

  async handleDeleteRun() {
    var model = await api.deleteObj("rosettarun", this.props.rosettarun_id, this.props.enqueueSnackbar);
    await this.handleGetModel()
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Websocket
          ref={this.socketRef}
          url={common.watcher_socket_location}
          onOpen={this.handleSocketOpen.bind(this)}
          onMessage={this.handleSocketUpdate.bind(this)}
          reconnect={true}
        />
        {this.state.model != null ? (
          <>
            <Paper className={classes.root}>
              <Typography variant="h6">Rosetta Run: <code>{this.state.model.uuid.substring(0, 8)}</code></Typography>

              {this.state.model.output_files.length > 0 && (
                <>
                  <Typography variant="h6">Top Structure
                <Download icon={true} url={"https://peat.caltech.edu" + this.state.model.output_files[0].blob} />
                  </Typography>
                  <PDBViewer name={this.state.model.uuid + "-key"} url={"https://peat.caltech.edu" + this.state.model.output_files[0].blob} />
                </>
              )}

              <Box mt={3}>
                <Typography variant="h6">Job Status</Typography>
              </Box>

              <Log status={this.state.model.status} opname={"template_prep"} />
              <Box mt={3}>
                <Log status={this.state.model.status} opname={"remodel"} />
              </Box>
              <Button onClick={this.handleDeleteRun}>
                Delete
              </Button>

            </Paper>
          </>
        ) : (
            <Loading />
          )
        }
      </>
    );
  }
}

const RosettaRun = withSnackbar(withStyles(styles.detail_styles)(PureRosettaRun));
RosettaRun.defaultProps = {
  model: null
};


export { RosettaRun };

