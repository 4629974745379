import { Paper } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import Highlight from 'react-highlight';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DateTime } from './decorators/DateTime';
import { DetailView } from './DetailView';

/**
 * Component that renders a detailed view of the Task
 */
class TaskComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      task: null,
      errors: null
    };
    this.model_title = obj => obj.name;
    this.model_name = 'Task';

    this.detail_fields = [
      {
        title: 'Name',
        field: 'task_name'
      },
      {
        title: 'Status',
        field: 'status'
      },
      {
        title: 'Time Finished',
        field: 'date_done',
        render: obj => <DateTime timestamp={obj.date_done} />
      },
      {
        title: 'Named Parameters',
        field: 'task_args',
        render: obj => (
          <Highlight className="js">{util.beautifyJSONString(obj.task_args)}</Highlight>
        )
      },
      {
        title: 'Keyword Parameters',
        field: 'task_kwargs',
        render: obj => (
          <Highlight className="js">{util.beautifyJSONString(obj.task_kwargs)}</Highlight>
        )
      },
      {
        title: 'Result',
        field: 'result',
        render: obj => <Highlight className="js">{util.beautifyJSONString(obj.result)}</Highlight>
      },
      {
        title: 'Error',
        field: 'traceback',
        render: obj => <Highlight className="python">{obj.traceback}</Highlight>
      }
    ];
  }

  async componentDidMount() {
    this.handleGetTask();
    this.timer = setInterval(() => this.handleGetTask(), 1000);
  }

  async handleGetTask() {
    let task = await api.get('task', this.props.task_id, this.props.enqueueSnackbar);
    if (task != null) {
      this.setState({
        task
      });
    }
  }

  componentWillUnmount() {
    this.timer = null;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          {this.state.task != null && (
            <DetailView
              model_name={this.model_name}
              model_title={this.model_title}
              detail_fields={this.detail_fields}
              model={this.state.task}
            />
          )}
        </Paper>
      </>
    );
  }
}

const Task = withSnackbar(withStyles(styles.detail_styles)(TaskComponent));
export { Task };

