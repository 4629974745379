import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import { DateTime } from './decorators/DateTime';
import { Number } from './decorators/Number';
import { Status } from './decorators/Status';




class SampleTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = this.props.tableRef;
    this.socketRef = React.createRef();
    this.columns = [
      {
        title: 'Name',
        field: 'name',
        defaultSort: 'asc',
        render: rowData => (
          <RouterLink to={'/sample/' + rowData.uuid + '/'} aria-label="view">
            {rowData.name}
          </RouterLink>
        )
      },
      {
        title: 'Status',
        field: 'status',
        render: rowData => (
          <Status key={rowData.uuid + '_status'} status={rowData.status} index={-1} />
        )
      },
      {
        title: 'Reads',
        field: 'reads',
        render: rowData => <Number num={rowData.reads} />
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: rowData => <DateTime timestamp={rowData.time_created} />
      }
    ];
    this.fields = this.columns.map(a => a.field);
    this.model_name = 'Sample';
  }

  handleSocketUpdate(data) {
    if (this.tableRef.current.state.hasOwnProperty('data')) {
      var merged = api.merge_update(data, this.tableRef.current.state.data);
      this.tableRef.current.setState({ data: merged });
    }
  }
  handleSocketOpen() {
    this.tableRef.current.onQueryChange();
  }
  handleSocketWatch(data) {
    // TODO more meaningful check to see whether the table's underlying
    // promise has resolved
    if (Array.isArray(data)) {
      var uuids = data.map(a => a.uuid);
      if (uuids.length > 0) {
        api.send_watch(this.socketRef, this.model_name, uuids, this.fields);
      }
    }
  }

  componentWillUnmount() {
    api.disconnect_socket(this.socketRef);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Websocket
          ref={this.socketRef}
          url={common.watcher_socket_location}
          onMessage={this.handleSocketUpdate.bind(this)}
          reconnect={true}
        />
        <div className={classes.topMargin}>
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(
                query,
                'sample',
                this.fields,
                this.props.experiment_id,
                'parent_experiment',
                this.handleSocketWatch.bind(this)
              )
            }
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 50],
              actionsColumnIndex: -1
            }}
            title="Samples"
            actions={[
              {
                icon: 'add',
                tooltip: 'Import Samples',
                isFreeAction: true,
                onClick: event => this.props.handleImportSamplesOpen()
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ]}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => true,
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  api.deleteObj('sample', oldData.uuid, this.props.enqueueSnackbar).then(resp => {
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    resolve();
                  });
                })
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}

const SampleTable = withSnackbar(withStyles(styles.detail_styles)(SampleTableComponent));
export { SampleTable };

