import { Button, Container, Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { TextAnnotator } from 'react-text-annotate';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import { DateTime } from './decorators/DateTime';
import { VariantSites } from './decorators/VariantSites';
import { DetailView } from './DetailView';
import { VariantTypeForm } from './VariantTypeForm';



/**
 * Component that renders a detailed view of the VariantType
 */
class PureVariantType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reference: null,
      model: null,
      errors: null,
      preview: null,
      editFormOpen: false
    };
    this.model_name = 'VariantType';
    this.model_rest = 'varianttype';
    this.model_id = this.props.varianttype_id;
    this.model_title = obj => obj.name;
    this.detail_fields = [
      {
        title: 'UUID',
        field: 'uuid'
      },
      {
        title: 'Name',
        field: 'name'
      },
      {
        title: 'Reference',
        field: 'reference',
        render: obj => (
          <RouterLink to={`/refseq/${obj.reference}/`} aria-label="view">
            Link
          </RouterLink>
        )
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: obj => <DateTime timestamp={obj.time_created} />
      },
      {
        title: 'Sites',
        field: 'sites',
        render: obj => <VariantSites sites={obj.sites} />
      }
    ];
  }

  async componentDidMount() {
    await this.handleGetModel();
    await this.handleGetReference();
  }

  async handleGetReference() {
    if (this.state.model != null) {
      let reference = await api.get(
        'refseq',
        this.state.model.reference,
        this.props.enqueueSnackbar
      );
      if (reference != null) {
        this.setState({
          reference: reference
        });
      }
    }
  }

  async handleGetModel() {
    let model = await api.get(this.model_rest, this.model_id, this.props.enqueueSnackbar);
    if (model != null) {
      this.setState({
        model: model
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          <DetailView
            model_name={this.model_name}
            model_title={this.model_title}
            detail_fields={this.detail_fields}
            model={this.state.model}
            edit={
              <Button variant="contained" onClick={() => this.setState({ editFormOpen: true })}>
                Edit
              </Button>
            }
          />
        </Paper>
        {this.state.model != null && (
          <VariantTypeForm
            open={this.state.editFormOpen}
            handleClose={() => this.setState({ editFormOpen: false })}
            variant_type={this.state.model}
            mode="edit"
            callback={this.handleGetModel}
          />
        )}
        {this.state.reference != null && (
          <Paper className={classes.root}>
            <Typography variant="h6">Variant Preview</Typography>
            <Container className={classes.preview}>
              <TextAnnotator
                content={this.state.reference.sequence}
                value={this.state.model.sites.map(x => ({
                  start: x.start,
                  end: x.stop,
                  tag: x.start.toString() + '-' + x.stop.toString() + ' SITE',
                  color: '#00fe00'
                }))}
              />
            </Container>
          </Paper>
        )}
      </>
    );
  }
}

const VariantType = withSnackbar(withStyles(styles.detail_styles)(PureVariantType));

export { VariantType };

