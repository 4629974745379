import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import { DateTime } from './decorators/DateTime';
import { VariantTypeForm } from './VariantTypeForm';




class VariantTypeTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      varianttype: {
        name: '',
        sites: [],
        reference: this.props.refseq_id
      },
      editFormOpen: false
    };
    this.columns = [
      {
        title: 'Name',
        field: 'name',
        render: rowData => (
          <RouterLink to={'/varianttype/' + rowData.uuid + '/'} aria-label="view">
            {rowData.name}
          </RouterLink>
        )
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: rowData => <DateTime timestamp={rowData.time_created} />
      }
    ];
    this.fields = this.columns.map(a => a.field);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.topMargin}>
          <VariantTypeForm
            open={this.state.editFormOpen}
            handleClose={() => this.setState({ editFormOpen: false })}
            mode="create"
            refseq_id={this.props.refseq_id}
            callback={this.tableRef.current && this.tableRef.current.onQueryChange}
          />
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(
                query,
                'varianttype',
                null,
                this.props.refseq_id,
                'reference',
                null
              )
            }
            title="Variant Type"
            options={{
              actionsColumnIndex: -1
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Add Variant Type',
                isFreeAction: true,
                onClick: event => this.setState({ editFormOpen: true })
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ]}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => true,
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  api.deleteObj('varianttype', oldData.uuid).then(resp => {
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    resolve();
                  });
                })
            }}
          />
        </div>
      </>
    );
  }
}

const VariantTypeTable = withSnackbar(withStyles(styles.detail_styles)(VariantTypeTableComponent));

export { VariantTypeTable };

