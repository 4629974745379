import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';

class RosettaForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleRunRosetta = this.handleRosetta.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.initialValues = {
      num_structs: 1,
      num_trajectory: 3,
      dr_cycles: 3,
      use_same_length_fragments: false,
      use_pose_relax: false,
      build_disulf: false,
    };
  }

  async handleRosetta() {
    await api.rosetta_model(this.props.variant_id, {},
      this.props.enqueueSnackbar,
      {
        success_notify: true
      }
    );
  }

  async submitForm(values, { setSubmitting, setErrors, setStatus, resetForm }) {
    setSubmitting(true);
    await this.handleRosetta();
    await this.props.callback();
    setSubmitting(false);
    resetForm(this.initialValues);
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik initialValues={this.initialValues} onSubmit={this.submitForm}>
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            setFieldValue,
            setTouched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = props;
          return (
            <Form>
              <Box mt={2}>
                <FormControl fullWidth>
                  <TextField margin="normal" variant="outlined" type="number" id="num_structs" label="Number of Structures" value={values.num_structs} onChange={handleChange} onBlur={handleBlur} name="num_structs" fullWidth />
                </FormControl>
                <FormControl fullWidth>
                  <TextField margin="normal" variant="outlined" type="number" id="num_trajectory" label="Number of Trajectories" value={values.num_trajectory} onChange={handleChange} onBlur={handleBlur} name="num_trajectory" fullWidth />
                </FormControl>
                <FormControl fullWidth>
                  <TextField margin="normal" variant="outlined" type="number" id="dr_cycles" label="Design-Refine Cycles" value={values.dr_cycles} onChange={handleChange} onBlur={handleBlur} name="dr_cycles" fullWidth />
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.build_disulf}
                        onChange={handleChange}
                        name="build_disulf"
                        color="primary"
                      />
                    }
                    label="Build Disulfide (find residue pairs that would make good disulfide bonds)"
                  />

                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.use_pose_relax}
                        onChange={handleChange}
                        name="use_pose_relax"
                        color="primary"
                      />
                    }
                    label="Use pose relax"
                  />

                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.use_same_length_fragments}
                        onChange={handleChange}
                        name="use_same_length_fragments"
                        color="primary"
                      />
                    }
                    label="Use same length fragments"
                  />

                </FormControl>

              </Box>
              <Grid
                className={classes.margin}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }
        }
      </Formik >
    );
  }
}
RosettaForm.defaultProps = {
  callback: Function.prototype
};


export default withSnackbar(withStyles(styles.form_styles)(RosettaForm));
