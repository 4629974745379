import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';




class VariantTypeFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      varianttype: {
        name: '',
        reference: this.props.refseq_id,
        sites: []
      }
    };
    if (props.hasOwnProperty('variant_type')) {
      this.initialValues = { ...this.props.variant_type };
    } else {
      this.initialValues = {
        name: '',
        reference: this.props.refseq_id,
        sites: []
      };
    }
    this.submitForm = this.submitForm.bind(this);
  }

  integerizeSites(values) {
    values.sites.forEach(function (part, index, arr) {
      arr[index].start = parseInt(arr[index].start);
      arr[index].stop = parseInt(arr[index].stop);
    });
    return values;
  }

  async submitForm(values, { resetForm }) {
    var payload = JSON.parse(JSON.stringify(values));
    payload = this.integerizeSites(payload);
    if (this.props.mode == 'create') {
      await api.create('varianttype', payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    } else {
      await api.update('varianttype', payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    }
    await this.props.callback();
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Formik initialValues={this.initialValues} onSubmit={this.submitForm}>
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset
            } = props;
            return (
              <>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  onClose={this.props.handleClose}
                  open={this.props.open}
                >
                  <DialogTitle id="form-dialog-title">Variant Type</DialogTitle>
                  <DialogContent>
                    <DialogContentText>Create a new Variant Type</DialogContentText>
                    <FormControl fullWidth>
                      <TextField
                        label="Name"
                        onChange={handleChange}
                        value={values.name}
                        autoFocus
                        name="name"
                        className={classes.margin}
                      />
                    </FormControl>
                    <Box className={classes.root}>
                      <MaterialTable
                        columns={[
                          {
                            title: 'Start',
                            field: 'start',
                            type: 'numeric'
                          },
                          {
                            title: 'Stop',
                            field: 'stop',
                            type: 'numeric'
                          }
                        ]}
                        data={values.sites}
                        title="Variant Sites"
                        options={{
                          search: false
                        }}
                        editable={{
                          isEditable: rowData => true,
                          isDeletable: rowData => true,
                          onRowAdd: newData =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                {
                                  if (!util.validate_site(newData)) {
                                    reject();
                                    return;
                                  }
                                  const data = values.sites;
                                  data.push(newData);
                                  setFieldValue('sites', data);
                                  resolve();
                                }
                                resolve();
                              }, 10);
                            }),
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                {
                                  if (!util.validate_site(newData)) {
                                    reject();
                                  }
                                  const data = values.sites;
                                  const index = data.indexOf(oldData);
                                  data[index] = newData;
                                  setFieldValue('sites', data);
                                }
                                resolve();
                              }, 10);
                            }),
                          onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                {
                                  const data = values.sites;
                                  const index = data.indexOf(oldData);
                                  data.splice(index, 1);
                                  setFieldValue('sites', data);
                                }
                                resolve();
                              }, 10);
                            })
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleSubmit} disabled={isSubmitting} color="primary">
                      {this.props.mode == 'create' ? 'Create' : 'Update'}
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const VariantTypeForm = withSnackbar(withStyles(styles.form_styles)(VariantTypeFormComponent));

export { VariantTypeForm };

VariantTypeForm.defaultProps = {
  callback: Function.prototype
};
