import { Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DateTime } from './decorators/DateTime';
import { Download } from './decorators/Download';
import { Number } from './decorators/Number';
import { DetailView } from './DetailView';
import Histogram from './Histogram';
import QualityGraph from './QualityGraph';



const detail_fields = [
  {
    title: 'UUID',
    field: 'uuid'
  },
  {
    title: 'Filename',
    field: 'filename'
  },
  {
    title: 'Size',
    field: 'size',
    render: obj => util.human_bytesize(obj.size)
  },
  {
    title: 'SHA256 Hash',
    field: 'sha256_hash',
    render: obj => <code>{obj.sha256_hash}</code>
  },
  {
    title: 'Flowcell ID',
    field: 'flowcell_id'
  },
  {
    title: 'Instrument',
    field: 'instrument'
  },
  {
    title: 'Run ID',
    field: 'run_id'
  },
  {
    title: 'Tile',
    field: 'tile'
  },
  {
    title: 'Origin',
    field: 'origin',
    render: obj => <Download url={obj.origin} />
  },
  {
    title: 'Reads',
    field: 'reads',
    render: obj => <Number num={obj.reads} />
  },
  {
    title: 'Download',
    field: 'blob',
    render: obj => <Download download={obj.filename} icon={true} url={obj.blob} />
  },
  {
    title: 'Time created',
    field: 'time_created',
    render: obj => <DateTime timestamp={obj.time_created} />
  }
];

/**
 * Component that renders a detailed view of the FASTQFile
 */
class PureFASTQFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      preview: null,
      errors: null
    };
    this.socketRef = React.createRef();
    this.model_name = 'FASTQFile';
    this.model_rest = 'fastqfile';
    this.watch_fields = ['progress', 'reads'];
    this.model_id = this.props.fastqfile_id;
    this.detail_fields = detail_fields;
    this.model_title = obj => obj.filename;
  }

  async componentDidMount() {
    await this.handleGetModel();
    await this.handleGetFilePreview();
  }

  componentWillUnmount() {
    api.disconnect_socket(this.socketRef);
  }

  async handleGetModel() {
    let model = await api.get(this.model_rest, this.model_id, this.props.enqueueSnackbar);
    if (model != null) {
      this.setState({
        model
      });
    }
  }

  async handleGetFilePreview() {
    let preview = await api.get_raw(
      `${common.api_location}/fastqfile/${this.model_id}/head/`,
      this.props.enqueueSnackbar
    );
    // clean up preview for visualization
    if (preview != null) {
      this.setState({
        preview: preview
      });
    }
  }

  handleSocketUpdate(data) {
    let merged = api.merge_update(data, this.state.model);
    this.setState({ model: merged });
  }

  handleSocketOpen(data) {
    api.send_watch(this.socketRef, this.model_name, [this.model_id], this.watch_fields);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.model != null && (
          <Paper className={classes.root}>
            <Websocket
              ref={this.socketRef}
              url={common.watcher_socket_location}
              onOpen={this.handleSocketOpen.bind(this)}
              onMessage={this.handleSocketUpdate.bind(this)}
              reconnect={true}
            />
            <DetailView
              model_name={this.model_name}
              model_title={this.model_title}
              detail_fields={this.detail_fields}
              model={this.state.model}
            />
          </Paper>
        )}
        {this.state.model != null && this.state.model.statistics != null && (
          <QualityGraph
            className={classes.root}
            qual={this.state.model.statistics.quality_bp}
            title="Quality (per base pair)"
          />
        )}
        {this.state.model != null && this.state.model.statistics != null && (
          <Histogram
            className={classes.root}
            hist={this.state.model.statistics.quality_sample}
            xlabel="PHRED Quality score"
            title="Mean Quality Histogram"
          />
        )}
        {this.state.model != null && this.state.model.statistics != null && (
          <Histogram
            className={classes.root}
            hist={this.state.model.statistics.gc_sample}
            xlabel="GC content (%)"
            title="% GC Content Histogram"
          />
        )}
        {this.state.preview != null && (
          <Paper style={{ overflow: 'auto' }} className={classes.root}>
            <Typography variant="h6">File Preview</Typography>
            {this.state.preview.map(elem => (
              <React.Fragment>
                <code>
                  <p>{elem.metadata}</p>
                  <p>{elem.seq}</p>
                  <p>{elem.plus}</p>
                  <p>{elem.quality}</p>
                </code>
              </React.Fragment>
            ))}
          </Paper>
        )}
      </>
    );
  }
}

const FASTQFile = withSnackbar(withStyles(styles.detail_styles)(PureFASTQFile));
export { FASTQFile };

