import { Container, Paper, Typography } from '@material-ui/core/';
import { useTheme } from '@material-ui/styles';
import ReactEcharts from 'echarts-for-react';
import React from 'react';

const no_legend = {
  display: false
};

/**
 * Generates a histogram plot from the histogram data, along with the bins
 * associated with the values.
 */
export default function EmpiricalCDF(props) {
  const theme = useTheme();
  if (props.bins.length != props.freq.length) {
    // the number of the bins should be the same as the number of data
    // points (frequencies)
    return <></>;
  }
  var total = props.freq.reduce((a, b) => a + b, 0);
  var cumul = 0;
  var data = [];
  var paired = props.bins.map((bin, idx) => [bin, props.freq[idx]]);
  paired.sort((a, b) => a[0] - b[0]);
  paired.forEach((pair, idx) => {
    cumul = cumul + pair[1] / total;
    data.push([pair[0], cumul]);
  });
  const fill = theme.palette.primary.main;
  return (
    <Paper className={props.className}>
      <Typography variant="h6">{props.title}</Typography>
      <Container>
        <ReactEcharts
          option={{
            grid: {
              left: '5%',
              top: 40,
              right: '5%',
              bottom: 50
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            yAxis: {
              name: 'fraction'
            },
            xAxis: {
              type: props.xaxis_type,
              name: props.xaxis_label,
              nameLocation: 'middle',
              minorTick: {
                show: true
              },
              minorSplitLine: {
                show: true
              }
            },
            series: [
              {
                data: data,
                type: 'scatter'
              }
            ],
            color: [fill]
          }}
        />
      </Container>
    </Paper>
  );
}
EmpiricalCDF.defaultProps = { height: 300, xaxis_type: 'log' };
