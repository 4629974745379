import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import { DateTime } from './decorators/DateTime';



const tasktable_columns = [
  {
    title: 'UUID',
    field: 'task_id',
    render: rowData => <Link to={`/task/${rowData.task_id}/`}>{rowData.task_id}</Link>
  },
  {
    title: 'Time finished',
    field: 'uuid',
    render: rowData => <DateTime timestamp={rowData.date_done} />
  },
  { title: 'Task name', field: 'task_name' },
  {
    title: 'Status',
    render: rowData => rowData.status
  }
];

/**
 * Stateless Component that renders a detailed view of the Task
 */
class TaskTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      errors: null
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.topMargin}>
          <MaterialTable
            columns={tasktable_columns}
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 50]
            }}
            data={query => api.table_data_query(query, 'task', null, null, null, null)}
            title="Tasks"
          />
        </div>
      </>
    );
  }
}

const TaskTable = withSnackbar(withStyles(styles.detail_styles)(TaskTableComponent));
export { TaskTable };

