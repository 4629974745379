import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DateTime } from './decorators/DateTime';
import { Download } from './decorators/Download';
import { Number } from './decorators/Number';
import { Progress } from './decorators/Progress';



class FASTQFileTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.socketRef = React.createRef();
    this.model_name = 'FASTQFile';
    this.socket_fields = ['progress', 'reads', 'size'];
    this.columns = [
      {
        title: 'Filename',
        field: 'filename',
        render: rowData => (
          <RouterLink to={`/fastqfile/${rowData.uuid}/`} aria-label="view">
            {rowData.filename}
          </RouterLink>
        )
      },
      { title: 'Flowcell ID', field: 'flowcell_id' },
      {
        title: 'Reads',
        field: 'reads',
        render: rowData => <Number num={rowData.reads} />
      },
      {
        title: 'Size',
        field: 'size',
        render: rowData => util.human_bytesize(rowData.size)
      },
      {
        title: 'Origin',
        field: 'origin',
        render: rowData => <code>{util.hostname(rowData.origin)}</code>
      },
      {
        title: 'Progress',
        field: 'progress',
        render: rowData => <Progress progress={rowData.progress} />
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: rowData => <DateTime timestamp={rowData.time_created} />
      },
      {
        title: 'Download',
        field: 'blob',
        render: rowData => <Download download={rowData.filename} icon={true} url={rowData.blob} />
      }
    ];
    this.fields = this.columns.map(a => a.field);
  }

  componentWillUnmount() {
    api.disconnect_socket(this.socketRef);
  }

  handleSocketUpdate(data) {
    if (this.tableRef.current.state.hasOwnProperty('data')) {
      const merged = api.merge_update(data, this.tableRef.current.state.data);
      this.tableRef.current.setState({ data: merged });
    }
  }

  handleSocketOpen() {
    this.tableRef.current.onQueryChange();
  }

  handleSocketWatch(data) {
    // TODO more meaningful check to see whether the table's underlying
    // promise has resolved
    if (Array.isArray(data)) {
      const uuids = data.map(a => a.uuid);
      if (uuids.length > 0) {
        api.send_watch(this.socketRef, this.model_name, uuids, this.socket_fields);
      }
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Websocket
          ref={this.socketRef}
          url={common.watcher_socket_location}
          onOpen={this.handleSocketOpen.bind(this)}
          onMessage={this.handleSocketUpdate.bind(this)}
          reconnect={true}
        />
        <div className={classes.topMargin}>
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(
                query,
                'fastqfile',
                this.fields,
                this.props.sample_id,
                'parent_sample',
                this.handleSocketWatch.bind(this)
              )
            }
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ]}
            title="FASTQ Files"
            options={{ selection: true }}
          />
        </div>
      </>
    );
  }
}

const FASTQFileTable = withSnackbar(withStyles(styles.detail_styles)(FASTQFileTableComponent));

export { FASTQFileTable };

