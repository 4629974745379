import { withStyles } from '@material-ui/core/styles';
import { ColormakerRegistry, Stage } from 'ngl';
import React from 'react';
import * as styles from '../../util/style.jsx';

class PDBViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: null
    };
  }

  componentDidMount() {
    this.setState({
      stage: new Stage(this.props.name)
    });
    var schemeId = ColormakerRegistry.addSelectionScheme([
      ["red", "370-377"],
    ], "insert");
    setTimeout(() =>
      this.state.stage.loadFile(this.props.url, { defaultRepresentation: true }).then(function (o) {
        o.removeAllRepresentations();
        o.addRepresentation('ball+stick', { color: schemeId });
        o.autoView();
      })
    );
    setInterval(() => this.state.stage.handleResize(), 100);
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        id={this.props.name}
        style={{ width: '100%', height: '100vh', backgroundColor: '#ff0000' }}
      ></div>
    );
  }
}

PDBViewer.defaultProps = { url: "rcsb://3ux1" };

export default withStyles(styles.detail_styles)(PDBViewer);
