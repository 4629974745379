import { amber, blue, green, red } from '@material-ui/core/colors/';
import { fade } from '@material-ui/core/styles';

export const dna_colors = {
  A: green['500'],
  T: amber['500'],
  C: blue['500'],
  G: red['500']
};

/**
 * RasMol style amino acid colors. See rationale for color scheme here:
 * http://acces.ens-lyon.fr/biotic/rastop/help/colour.htm
 */
export const aa_colors = {
  D: '#E60A0A',
  E: '#E60A0A',
  C: '#E6E600',
  M: '#E6E600',
  K: '#145AFF',
  R: '#145AFF',
  S: '#FA9600',
  T: '#FA9600',
  F: '#3232AA',
  Y: '#3232AA',
  N: '#00DCDC',
  Q: '#00DCDC',
  G: '#EBEBEB',
  L: '#0F820F',
  V: '#0F820F',
  I: '#0F820F',
  A: '#C8C8C8',
  W: '#B45AB4',
  H: '#8282D2',
  P: '#DC9682',
  Others: '#BEA06E',
  X: '#BEA06E',
  _: '#BEA06E'
};

export const menu_styles = theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  title: {
    flexGrow: 1
  },
  buttonLink: {
    textDecoration: 'none'
  },
  block: {
    display: 'block'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 200,
      '&:focus': {
        width: 300
      }
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

/**
 * Dialog form shared styles
 */
export const form_styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  table: {
    marginTop: theme.spacing(3)
  },
  collapse: {
    width: '100%',
    padding: theme.spacing(3, 2)
  },
  dialog_button: {
    margin: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  margin: {
    margin: theme.spacing(1)
  }
});

/**
 * Dialog form shared styles
 */
export const transfer_list_styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  paper: {
    height: 400,
    overflow: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  table: {
    marginTop: theme.spacing(3)
  },
  collapse: {
    width: '100%',
    padding: theme.spacing(3, 2)
  },
  dialog_button: {
    margin: theme.spacing(1)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  margin: {
    margin: theme.spacing(1)
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: '100%',
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  }
});

/**
 * Detailed object view shared styles
 */
export const detail_styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3, 2)
  },
  block: {
    display: 'block'
  },
  topMargin: {
    marginTop: theme.spacing(3)
  },
  preview: {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all'
  },
  margin: {
    margin: theme.spacing(1)
  }
});
