import { Avatar, Chip, Tooltip } from '@material-ui/core/';
import { blue, green, grey, red } from '@material-ui/core/colors/';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { DateTime } from './DateTime';

const status_styles = makeStyles(theme => ({
  RUNNING: {
    backgroundColor: green['500'],
    color: '#fff',
    margin: theme.spacing(0.5)
  },
  COMPLETED: {
    backgroundColor: blue['500'],
    color: '#fff',
    margin: theme.spacing(0.5)
  },
  ERROR: {
    backgroundColor: red['500'],
    color: '#fff',
    margin: theme.spacing(0.5)
  }
}));
export function StatusChip(props) {
  const classes = status_styles();
  var status_icons = {
    RUNNING: {
      icon: <DirectionsRunIcon className={classes['RUNNING']} fontSize="small" />
    },
    COMPLETED: {
      icon: <CheckCircleIcon className={classes['COMPLETED']} fontSize="small" />
    },
    ERROR: {
      icon: <ErrorIcon className={classes['ERROR']} />
    },
    UNKNOWN: { icon: <React.Fragment></React.Fragment>, color: grey['500'] }
  };
  if (props.op) {
    var op = props.op;
    var status_icon =
      op.status in status_icons ? status_icons[op.status].icon : status_icons['UNKNOWN'].icon;
    var msg = op.general_msg + (op.status == 'RUNNING' ? ' (' + op.progress + '%)' : '');
    return (
      <Tooltip title={<DateTime timestamp={op.time_started} />}>
        <Chip
          size="small"
          avatar={<Avatar className={classes[op.status]}>{status_icon}</Avatar>}
          label={msg}
          className={classes[op.status]}
        />
      </Tooltip>
    );
  };
  return <React.Fragment></React.Fragment>;
}