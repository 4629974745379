import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import * as styles from '../util/style.jsx';
import { ExperimentTable } from './ExperimentTable';
import { RefSeqTable } from './RefSeqTable';

/**
 * Component that renders a Dashboard (homepage) for an authenticated user
 */
class DashboardComponent extends React.Component {
  render() {
    return (
      <>
        <ExperimentTable />
        <RefSeqTable />
      </>
    );
  }
}

const Dashboard = withStyles(styles.detail_styles)(DashboardComponent);
export { Dashboard };

