
/**
 * Decorates a (possibly floating point,) number, with fixed digits.
 * Also renders an uncertainty if present
 */
export function Number(props) {
  var output = '';
  if (props.num != null) {
    output = props.num.toLocaleString(undefined, { maximumFractionDigits: props.digits });
  }

  if (props.uncertainty != null) {
    output += '±' + props.uncertainty.toFixed(props.digits);
  }
  return output;
}
Number.defaultProps = {
  uncertainty: null,
  digits: 0
};
