import { Container, Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { jseqlogo, sequence_logo } from '../libs/jseqlogo';
import { detail_styles } from '../util/style.jsx';

function sum(obj) {
  var sum = 0;
  for (var el in obj) {
    if (obj.hasOwnProperty(el)) {
      sum += parseFloat(obj[el]);
    }
  }
  return sum;
}

/**
 * Shows a SequenceLogo
 */
/*

const amino = [
  'I',
  'V',
  'L',
  'F',
  'C',
  'M',
  'A',
  'W',
  'G',
  'T',
  'S',
  'Y',
  'P',
  'H',
  'N',
  'D',
  'Q',
  'E',
  'K',
  'R',
  'X',
  '_'
];
export default function SequenceLogo(props) {
  const chart_info = {
    labels: [...Array(props.logo[0].length).keys()].map(x => x + 1),
    datasets: amino.map(function(aa) {
      return {
        label: aa,
        data: props.logo[0].map(obj => obj[aa]),
        backgroundColor: aa_colors[aa],
        hoverBorderWidth: 0,
        borderWidth: 0
      };
    })
  };
  const options = {
    legend: { display: true },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Frequency'
          },
          stacked: true
        }
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Position'
          },
          stacked: true
        }
      ]
    }
  };
  return (
    <Paper className={props.className}>
      <Typography variant="h6">{props.title}</Typography>
      <Container>
        <Bar data={chart_info} options={options} />
      </Container>
    </Paper>
  );
}
*/

const amino = [
  'I',
  'V',
  'L',
  'F',
  'C',
  'M',
  'A',
  'W',
  'G',
  'T',
  'S',
  'Y',
  'P',
  'H',
  'N',
  'D',
  'Q',
  'E',
  'K',
  'R'
];
class PureSequenceLogo extends React.Component {
  constructor(props) {
    super(props);
    this.logoref = React.createRef();
    this.containerRef = React.createRef();
    this.padding = 20;
  }
  updateDimensions = () => {
    var data = {};
    amino.forEach(aa => (data[aa] = this.props.logo[0].map(obj => obj[aa] / sum(obj) || 0)));
    var options = {
      colors: jseqlogo.colors.aminoacids
    };
    sequence_logo(
      this.logoref.current,
      this.containerRef.current.offsetWidth - this.padding,
      this.props.height,
      data,
      options
    );
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  componentDidMount() {
    var data = {};
    amino.forEach(aa => (data[aa] = this.props.logo[0].map(obj => obj[aa] / sum(obj) || 0)));
    var options = {
      colors: jseqlogo.colors.aminoacids
    };
    sequence_logo(
      this.logoref.current,
      this.containerRef.current.offsetWidth - this.padding,
      this.props.height,
      data,
      options
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Typography variant="h6">{this.props.title}</Typography>
        <Container ref={this.containerRef}>
          <canvas ref={this.logoref} />
        </Container>
      </Paper>
    );
  }
}

PureSequenceLogo.defaultProps = { height: 500 };
const SequenceLogo = withStyles(detail_styles)(PureSequenceLogo);
export { SequenceLogo };

