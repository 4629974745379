import { Container, Paper, Typography } from '@material-ui/core/';
import { useTheme } from '@material-ui/styles';
import ReactEcharts from 'echarts-for-react';
import React from 'react';

const no_legend = {
  display: false
};

/**
 * Plots quality information (per base pair) using PHRED quality scale.
 */
export default function QualityGraph(props) {
  const data = props.qual.map((avg, idx) => [(idx + 1).toString(), avg]);
  const theme = useTheme();
  const fill = theme.palette.primary.main;
  return (
    <Paper className={props.className}>
      <Typography variant="h6">{props.title}</Typography>
      <Container>
        <ReactEcharts
          option={{
            grid: {
              left: '3%',
              top: 40,
              right: '3%',
              bottom: 40
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            xAxis: {},
            yAxis: {},
            series: [
              {
                symbolSize: 10,
                data: data,
                type: 'scatter'
              }
            ],
            color: [fill]
          }}
        />
      </Container>
    </Paper>
  );
}
QualityGraph.defaultProps = { height: 300 };
