import { Box, Button, Container, Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import { AminoAcidVariant } from './decorators/AminoAcidVariant';
import { NucleotideVariant } from './decorators/NucleotideVariant';
import { DetailView } from './DetailView';
import RosettaForm from './RosettaForm';
import { RosettaRun } from './RosettaRun';
import VariantForm from './VariantForm';



/**
 * Component that renders a detailed view of the Sample
 */
/**
 * Required props
 * model_name: actual model name used in Django: Sample
 * model_id: uuid associated with model
 * watch_fields: fields to watch on the model
 * detail_fields: fields that should be displayed on detail panel
 */

class PureVariant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      errors: null,
      variants: null,
      editFormOpen: false,
      rosetta_runs: null
    };
    this.socketRef = React.createRef();
    this.model_name = 'Variant';
    this.model_rest = 'variant';
    this.model_id = this.props.variant_id;
    this.model_title = obj => obj.name;
    this.detail_fields = [
      {
        title: 'UUID',
        field: 'uuid'
      },
      {
        title: 'Name',
        field: 'name'
      },
      {
        title: 'Variant Type',
        field: 'variant_type',
        render: obj => (
          <RouterLink to={`/varianttype/${obj.variant_type.uuid}/`} aria-label="view">
            {obj.variant_type.name}
          </RouterLink>
        )
      },
      {
        title: 'Nucleotide Variant',
        field: 'nt',
        render: obj => (
          <NucleotideVariant
            variant={obj.nt}
            variant_type={obj.variant_type}
            reference={obj.variant_type.reference}
          />
        )
      },
      {
        title: 'Amino Acid Variant',
        field: 'aa',
        render: obj => (
          <AminoAcidVariant
            variant={obj.aa}
            variant_type={obj.variant_type}
            reference={obj.variant_type.reference}
          />
        )
      }
    ];
    this.handleGetModel = this.handleGetModel.bind(this);
    this.handleGetRuns = this.handleGetRuns.bind(this);
  }

  async componentDidMount() {
    await this.handleGetModel();
    await this.handleGetRuns();
  }

  async handleGetModel() {
    var model = await api.get(this.model_rest, this.model_id);
    if (model != null) {
      this.setState({
        model
      });
    } else {
      if (this.props.location.state != null) {
        const payload = this.props.location.state;
        await api.create('variant', payload, this.props.enqueueSnackbar);
        var model = await api.get(this.model_rest, this.model_id);
        if (model != null) {
          this.setState({
            model
          });
        }
      }
    }

  }

  async handleGetRuns() {
    const query = { filters: [] };
    api.pack_filter(query, 'variant', this.props.variant_id);
    api.pack_filter(query, 'no_page', '');
    const rosetta_runs = await api.getList(
      'rosettarun',
      query,
      null,
      this.props.enqueueSnackbar
    );
    if (rosetta_runs != null) {
      this.setState({
        rosetta_runs: rosetta_runs
      });
    }

  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          <DetailView
            model_name={this.model_name}
            model_title={this.model_title}
            detail_fields={this.detail_fields}
            model={this.state.model}
            edit={
              <Button variant="contained" onClick={() => this.setState({ editFormOpen: true })}>
                Edit
              </Button>
            }
          />
        </Paper>
        {this.state.model != null && (
          <>
            <VariantForm
              open={this.state.editFormOpen}
              handleClose={() => this.setState({ editFormOpen: false })}
              variant={this.state.model}
              mode="edit"
              callback={this.handleGetModel}
            />
            <Paper className={classes.root}>
              <Typography variant="h6">Full Nucleotide Sequence</Typography>
              <Container className={classes.preview}>
                <Box fontFamily="Monospace">
                  <code>{this.state.model.full_nt}</code>
                </Box>
              </Container>
            </Paper>
            <Paper className={classes.root}>
              <Typography variant="h6">Full Amino Acid Sequence</Typography>
              <Container className={classes.preview}>
                <Box fontFamily="Monospace">
                  <code>{this.state.model.full_aa}</code>
                </Box>
              </Container>
            </Paper>
            <Paper className={classes.root}>
              <Typography variant="h6">Run Rosetta (Remodel)</Typography>
              <RosettaForm callback={this.handleGetRuns} variant_id={this.props.variant_id} />
            </Paper>
            {this.state.rosetta_runs != null && (<>{
              this.state.rosetta_runs.map((run, idx) =>
                (
                  <RosettaRun key={"rosettarun_" + idx} model={run} rosettarun_id={run.uuid} />

                )
              )} </>)
            }
          </>
        )}
      </>
    );
  }
}
const Variant = withSnackbar(withRouter(withStyles(styles.detail_styles)(PureVariant)));

export { Variant };

