import { CircularProgress, Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import * as styles from '../util/style.jsx';

class Loading extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles.detail_styles)(Loading);
