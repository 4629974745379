import { Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import { DateTime } from './decorators/DateTime';
import { RefSeqForm } from './RefSeqForm';




class RefSeqTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.state = {
      refseq: {
        name: '',
        sequence: ''
      },
      formOpen: false
    };
    this.handleFormOpen = this.handleFormOpen.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.columns = [
      {
        title: 'Name',
        field: 'name',
        render: rowData => (
          <RouterLink to={'/refseq/' + rowData.uuid + '/'} aria-label="view">
            {rowData.name}
          </RouterLink>
        )
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: rowData => <DateTime timestamp={rowData.time_created} />
      }
    ];
    this.fields = this.columns.map(a => a.field);
  }

  handleChange(event) {
    let { name } = event.target;
    let { value } = event.target;
    this.setState(({ refseq }) => ({
      refseq: {
        ...refseq,
        [name]: value
      }
    }));
  }

  handleFormOpen() {
    this.setState({ formOpen: true });
  }

  handleFormClose() {
    this.setState({ formOpen: false });
  }

  async handleSubmit() {
    let refseq = await api.create('refseq', this.state.refseq, this.props.enqueueSnackbar);
    if (refseq != null) {
      this.handleFormClose();
    }
    if (this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.topMargin}>
          <RefSeqForm
            handleChange={this.handleChange}
            open={this.state.formOpen}
            handleClose={this.handleFormClose}
            handleSubmit={this.handleSubmit}
            refseq={this.state.refseq}
          />
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              new Promise((resolve, reject) => {
                api.getList('refseq', query, this.props.enqueueSnackbar).then(resp => {
                  if (resp != null) {
                    resolve({
                      data: resp.results,
                      page: query.page,
                      totalCount: resp.count
                    });
                  } else {
                    reject('Invalid request');
                  }
                });
              })
            }
            title="Reference Sequence"
            options={{
              actionsColumnIndex: -1
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Add Reference Sequence',
                isFreeAction: true,
                onClick: event => this.handleFormOpen()
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ]}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => rowData.name != "AAV9",
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  api.deleteObj('refseq', oldData.uuid, this.props.enqueueSnackbar).then(resp => {
                    this.tableRef.current && this.tableRef.current.onQueryChange();
                    resolve();
                  });
                })
            }}
          />
        </div>
      </>
    );
  }
}

class PureRefSeqSelector extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Typography variant="h5">{this.props.refseq.name}</Typography>
      </Paper>
    );
  }
}

const RefSeqTable = withSnackbar(withStyles(styles.detail_styles)(RefSeqTableComponent));

export { RefSeqTable };

