import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core/';
import MenuBookIcon from '@material-ui/icons/Book';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import SampleIcon from './icons/SampleIcon.jsx';

export const drawer_styles = theme => ({
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
});

class MainDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.links = [
      {
        title: 'Experiments',
        link: '/experiment_table/',
        icon: <GroupWorkIcon />
      },
      {
        title: 'Samples',
        link: '/sample_table/',
        icon: <SampleIcon />
      },
      {
        title: 'FASTQ Files',
        link: '/fastqfile_table/',
        icon: <InsertDriveFileIcon />
      },
      {
        title: 'Reference Sequences',
        link: '/refseq_table/',
        icon: <MenuBookIcon />
      }
    ];
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Drawer open={this.props.open} onClose={() => this.props.toggleDrawer(false)}>
          <div
            className={classes.fullList}
            role="presentation"
            onClick={() => this.props.toggleDrawer(false)}
            onKeyDown={() => this.props.toggleDrawer(false)}
          >
            <List>
              {this.links.map((elem, index) => (
                <ListItem
                  onClick={() => this.props.history.push(elem.link)}
                  button
                  key={elem.title}
                >
                  <ListItemIcon>{elem.icon}</ListItemIcon>
                  <ListItemText primary={elem.title} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withRouter(withStyles(drawer_styles)(MainDrawer));
