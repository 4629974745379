import * as common from './common.js';

/**
 * Pack an extra filter into a query object
 */
export function pack_filter(query, field, value) {
  query.filters.push({ column: { field }, value });
}

/**
 * Parses the query object given by the Table's request into pagination query
 * parameters, filter parameters, etc., and returns a querystring to be
 * appended to the URL
 */
function parse_query(query) {
  const params = new URLSearchParams();
  if (query.hasOwnProperty('page') && query.hasOwnProperty('pageSize')) {
    params.append('page', query.page + 1);
    params.append('pageSize', query.pageSize);
  }
  if (query.hasOwnProperty('no_page')) {
    params.append('no_page', 'true');
  }
  if (
    query.hasOwnProperty('orderBy') &&
    query.hasOwnProperty('orderDirection') &&
    query.orderBy != null &&
    query.orderDirection != null
  ) {
    const ordering =
      query.orderDirection == 'asc' ? query.orderBy.field : `-${query.orderBy.field}`;
    params.append('ordering', ordering);
  }
  if (query.hasOwnProperty('search') && query.search != null && query.search != '') {
    params.append('search', query.search);
  }
  query.filters.forEach((filter, index) => {
    params.append(filter.column.field, filter.value);
  });
  return `?${params.toString()}`;
}

/**
 * Create object by passing serialized information
 */
export async function create(objType, object, notify) {
  try {
    const res = await fetch(`${common.api_location}/${objType}/`, common.gen_post_request(object));
    common.handleErrors(res, notify);
    const data = await res.json();
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Get serialized object by id
 */
export async function get(objType, id, fields = null, notify) {
  try {
    const res = await fetch(`${common.api_location}/${objType}/${id}/`, common.gen_get_request());
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const resp = await res.json();
      return resp;
    }
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

export function get_sample_dump_url(id) {
  var url = `${common.api_location}/sample/${id}/dump/`;
  return common.auth_decorate_url(url);
}

export function get_join_dump_url(id) {
  var url = `${common.api_location}/experiment/${id}/join_dump/`;
  return common.auth_decorate_url(url);
}

/**
 * Get serialized object by id
 */
export async function get_raw(url, notify) {
  try {
    const res = await fetch(url, common.gen_get_request());
    common.handleErrors(res, notify);
    const task = await res.json();
    return task;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Get a list of objects from the server
 */
export async function getList(objType, query, fields, notify) {
  try {
    if (query == null) {
      query = { filters: [] };
    }
    if (fields != null && Array.isArray(fields)) {
      fields.forEach(element => {
        pack_filter(query, 'tab', element);
      });
    }
    const res = await fetch(
      `${common.api_location}/${objType}/${parse_query(query)}`,
      common.gen_get_request()
    );
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const tasks = await res.json();
      return tasks;
    }
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Delete an object from the server
 */
export async function deleteObj(objType, id, notify) {
  try {
    const res = await fetch(`${common.api_location}/${objType}/${id}`, common.gen_delete_request());
    common.handleErrors(res, notify);
    return '';
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Creates a set of Samples from each element in the samples array provided
 */
export async function createSamples(samples, notify, success_notify = false) {
  try {
    const res = await fetch(`${common.api_location}/sample/`, common.gen_post_request(samples));
    const sample_ids = [];
    var err = common.handleErrors(res, notify);
    const data = await res.json();

    if (success_notify && err == null) {
      common.notifyOrLog(`Added ${data.length} sample(s).`, 'success', notify);
    }

    return data;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
    return null;
  }
}

/**
 * Updates a set of Samples from each element in the samples array provided
 */
export async function update(objType, obj, notify) {
  try {
    const res = await fetch(
      `${common.api_location}/${objType}/${obj.uuid}/`,
      common.gen_patch_request(obj)
    );
    common.handleErrors(res, notify);
    const data = await res.json();
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

export async function rosetta_model(variant_id, payload, notify, success_notify = false) {
  try {
    const res = await fetch(`${common.api_location}/variant/${variant_id}/model/`, common.gen_post_request(payload));
    var err = common.handleErrors(res, notify);
    if (err == null) {
      const resp = await res.json();
      return resp;
    }
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Calculates the enrichments for each of the sample ids specified
 */
export async function calculateEnrichment(samples, notify, success_notify = false) {
  try {
    const res = await fetch(
      `${common.api_location}/calculate_enrichment`,
      common.gen_post_request({ samples: samples })
    );
    var err = common.handleErrors(res, notify);

    const data = res.json();
    if (success_notify && err == null) {
      common.notifyOrLog(
        `Calculating enrichments for ${samples.length} sample(s).`,
        'success',
        notify
      );
    }
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Imports the proper FASTQFiles given sample ids and remote connection
 * information. The server will find fastq.gz files that match the indices
 * that correspond to the given samples, and imports them into the system.
 * (Downloading them to object store).
 */
export async function importSamples(payload, notify, success_notify = false) {
  try {
    const res = await fetch(
      `${common.api_location}/import_samples`,
      common.gen_post_request(payload)
    );
    var err = common.handleErrors(res, notify);

    const data = res.json();
    if (success_notify && err == null) {
      common.notifyOrLog(`Importing ${payload.samples.length} sample(s).`, 'success', notify);
    }
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

/**
 * Aligns the given samples
 *
 */
export async function alignSamples(payload, notify, success_notify = false) {
  try {
    const res = await fetch(
      `${common.api_location}/align_samples`,
      common.gen_post_request(payload)
    );
    var err = common.handleErrors(res, notify);

    const data = res.json();
    if (success_notify && err == null) {
      common.notifyOrLog(`Aligning ${payload.samples.length} sample(s).`, 'success', notify);
    }
    return data;
  } catch (e) {
    common.notifyOrLog(e.message, 'error', notify);
  }
}

export function socketIsOpen(socket) {
  return socket != null && socket.state != null && socket.state.ws.readyState == WebSocket.OPEN;
}

/**
 *
 * Sends a "watch" request to the server. The server will send updates
 * when the model changes, and will only send the fields specified here.
 *
 * @param {*} socket The socket to send the message to
 * @param {*} model  The model (Sample, Experiment, RefSeq, etc.)
 * @param {*} fields Fields to receive updates on
 */
export function send_watch(socketRef, model, uuid, fields) {
  const socket = socketRef.current;
  if (socketIsOpen(socket)) {
    const request = {
      type: 'watch',
      model,
      uuid,
      fields
    };
    socket.state.ws.send(JSON.stringify(request));
  }
}

/**
 *
 * Sends an "unwatch" request to the server. The server will stop updates
 * when the model changes
 *
 * @param {ref} socket The socket Ref to send the message to
 * @param {*} model  The model (Sample, Experiment, RefSeq, etc.)
 * @param {*} fields Fields to stop receiving updates on, (if all, no more updates)
 */
export function send_unwatch(socketRef, model, uuid, fields) {
  const socket = socketRef.current;
  if (socketIsOpen(socket)) {
    if (socket.state.ws.readyState == WebSocket.OPEN) {
      // ensure socket is open before sending message
      const request = {
        type: 'unwatch',
        model,
        uuid,
        fields
      };
      socket.state.ws.send(JSON.stringify(request));
    }
  }
}

/**
 * Merges updates sent by the server to the local versions.
 * @param {*} update Serialized version of the updated obj from server
 * @param {*} old The old client-side version of the object or obj array
 */
export function merge_update(update, old) {
  const result = JSON.parse(update);
  if (result.hasOwnProperty('type')) {
    return old;
  }
  if (Array.isArray(old)) {
    const merged = old.slice(0);
    for (let i = 0; i < merged.length; i++) {
      if (merged[i].uuid == result.uuid) {
        merged[i] = Object.assign(merged[i], result);
      }
    }
    return merged;
  }
  return Object.assign(old, result);
}

export function disconnect_socket(socketRef) {
  const socket = socketRef.current;
  if (socketIsOpen(socket)) {
    if (socket.state.ws.readyState == WebSocket.OPEN) {
      socket.state.ws.close();
    }
  }
}

/**
 * Table data query
          let old_uuids = old.map(a => a.uuid)
          send_unwatch(socketRef, model_name, old_uuids, socket_fields)
 */
export function table_data_query(
  prev_query,
  model_rest,
  fields,
  parent_id,
  parent_field,
  handleSocketWatch
) {
  return new Promise((resolve, reject) => {
    // create a copy of the query object before we pack it.
    const query = JSON.parse(JSON.stringify(prev_query));
    if (parent_id != null && parent_field != null) {
      pack_filter(query, parent_field, parent_id);
    }
    getList(model_rest, query, fields).then(resp => {
      if (resp != null) {
        if (handleSocketWatch != null) {
          handleSocketWatch(resp.results);
        }
        resolve({
          data: resp.results,
          page: query.page,
          totalCount: resp.count
        });
      } else {
        reject('Query failed');
      }
    });
  });
}
