import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import { DateTime } from './decorators/DateTime';
import ExperimentForm from './ExperimentForm';


class ExperimentTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experiment: {
        name: '',
        variant_type: ''
      },
      formOpen: false,
      varianttypes: []
    };
    this.tableRef = React.createRef();
    this.socketRef = React.createRef();
    this.handleFormOpen = this.handleFormOpen.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
    this.columns = [
      {
        title: 'Name',
        field: 'name',
        render: rowData => (
          <RouterLink to={`/experiment/${rowData.uuid}/`} aria-label="view">
            {rowData.name}
          </RouterLink>
        )
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: rowData => <DateTime timestamp={rowData.time_created} />,
        defaultSort: 'desc'
      }
    ];
    this.fields = this.columns.map(a => a.field);
  }

  async componentDidMount() {
    await this.handleGetVariantTypes();
  }

  handleSocketUpdate(data) {
    if (this.tableRef.current.state.hasOwnProperty('data')) {
      const merged = api.merge_update(data, this.tableRef.current.state.data);
      this.tableRef.current.setState({ data: merged });
    }
  }

  handleSocketOpen() {
    this.tableRef.current.onQueryChange();
  }

  handleSocketWatch(data) {
    // TODO more meaningful check to see whether the table's underlying
    // promise has resolved
    if (Array.isArray(data)) {
      const uuids = data.map(a => a.uuid);
      if (uuids.length > 0) {
        api.send_watch(this.socketRef, this.model_name, uuids, this.socket_fields);
      }
    }
  }
  handleFormOpen() {
    this.setState({ formOpen: true });
  }

  handleFormClose() {
    this.setState({ formOpen: false });
    if (this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  async handleGetVariantTypes() {
    const varianttypes = await api.getList(
      'varianttype',
      null,
      ['name'],
      this.props.enqueueSnackbar
    );
    if (varianttypes != null) {
      this.setState({
        varianttypes: varianttypes.results
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Websocket
          ref={this.socketRef}
          url={common.watcher_socket_location}
          onMessage={this.handleSocketUpdate.bind(this)}
          reconnect
        />
        <div className={classes.topMargin}>
          {this.state.varianttypes != null && (
            <ExperimentForm
              handleChange={this.handleChange}
              open={this.state.formOpen}
              handleClose={this.handleFormClose}
              handleSubmit={this.handleSubmit}
              experiment={this.state.experiment}
              varianttypes={this.state.varianttypes}
              mode="create"
            />
          )}
          <MaterialTable
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(
                query,
                'experiment',
                this.fields,
                null,
                null,
                this.handleSocketWatch.bind(this)
              )
            }
            title="Experiments"
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 50],
              actionsColumnIndex: -1
            }}
            actions={[
              {
                icon: 'add',
                tooltip: 'Add Experiment',
                isFreeAction: true,
                onClick: event => this.handleFormOpen()
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange()
              }
            ]}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => false,
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  api
                    .deleteObj('experiment', oldData.uuid, this.props.enqueueSnackbar)
                    .then(resp => {
                      this.tableRef.current && this.tableRef.current.onQueryChange();
                      resolve();
                    });
                })
            }}
          />
        </div>
      </>
    );
  }
}

const ExperimentTable = withSnackbar(withStyles(styles.detail_styles)(ExperimentTableComponent));
export { ExperimentTable };

