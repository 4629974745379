import { Container, Paper, Typography } from '@material-ui/core/';
import { useTheme } from '@material-ui/styles';
import ReactEcharts from 'echarts-for-react';
import React from 'react';
import * as util from '../util/util.jsx';

const no_legend = {
  display: false
};

/**
 * Generates a histogram plot from the histogram data, along with the bins
 * associated with the values.
 */
export default function Histogram(props) {
  const theme = useTheme();
  if (props.hist.length == 0) {
    return <></>;
  }
  const bsize = 1;
  const min = Math.min(...props.hist);
  try {
    const hist = util.histogram(props.hist, bsize);
    var data = hist.map((val, idx) => [Math.floor(min + idx * bsize), val]);
  } catch (e) {
    var data = [];
  }
  const fill = theme.palette.primary.main;
  return (
    <Paper className={props.className}>
      <Typography variant="h6">{props.title}</Typography>
      <Container>
        <ReactEcharts
          option={{
            grid: {
              left: '5%',
              top: 40,
              right: '5%',
              bottom: 40
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                animation: false
              }
            },
            xAxis: {},
            yAxis: {},
            series: [
              {
                data: data,
                type: 'bar'
              }
            ],
            color: [fill]
          }}
        />
      </Container>
    </Paper>
  );
}
Histogram.defaultProps = { height: 300 };
