import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import '../../node_modules/react-vis/dist/style.css';
import * as auth from '../api/auth.js';
import { Dashboard } from './Dashboard';
import { Experiment } from './Experiment';
import { ExperimentTable } from './ExperimentTable';
import { FASTQFile } from './FASTQFile';
import { FASTQFileTable } from './FASTQFileTable';
import Login from './Login';
import { NavBar } from './NavBar';
import { RefSeq } from './RefSeq';
import { RefSeqTable } from './RefSeqTable';
import { RosettaRun } from './RosettaRun';
import { Sample } from './Sample';
import { SampleTable } from './SampleTable';
import { SocialAuthCallback } from './SocialAuthCallback';
import { Task } from './Task';
import { TaskTable } from './TaskTable';
import { Variant } from './Variant';
import { VariantType } from './VariantType';
import { LiteMolViewer } from './Viewer/LiteMolViewer';
import PDBViewer from './Viewer/PDBViewer';




const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() === true ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

/**
 * Generates a copyright string to be placed at the bottom of the
 * webpage
 *
 * @return {HTML} Copyright message
 */
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://caltech.edu/">
        California Institute of Technology
      </Link>
      <span>, {new Date().getFullYear()}</span>
    </Typography>
  );
}

function Base(content) {
  return (
    <>
      <NavBar authenticated={auth.isAuthenticated()} />
      <Container>{content}</Container>
      <Container maxWidth="sm">
        <Box my={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

function Index() {
  return Base(
    <Box m={15}>
      <Typography variant="h3">Protein Engineering Analytics Tool</Typography>

      <Typography variant="body1">
        <p>Office of the Provost + Gradinaru Lab, Caltech</p>
        <p>
          Vision: Cloudbased platform for management and analysis of Next generation sequencing
          viral mutant datasets
        </p>

        <p>
          The Gradinaru Lab engineers customized Adeno-associated viruses (AAVs) to selectively
          deliver genes to specific cell types and tissues. For example, a major therapeutic
          challenge is gene delivery to neurons, as the virus must cross the Blood Brain Barrier and
          further penetrate individual neurons. In 2017, the Gradinaru Lab demonstrated that a
          variant of the natural AAV9 could selectively penetrate the BBB, 55% of cortical neurons,
          and 69% of striatal neurons.
        </p>
        <p>
          Professor Gradinaru’s group uses directed evolution to identify these virus variants. The
          directed evolution method involves inserting partially random DNA sequences at specific
          mutation sites, to create many unique variants of the virus. These variants are introduced
          into mice, whose tissues are subsequently sequenced using deep sequencing. If a variant of
          a virus has penetrated a tissue, it will be discovered in high quantities in the
          sequencing for that tissue. Several rounds of an experiment may be performed to narrow the
          pool of potential variants and to produce a more optimal virus variant for a particular
          cell type and/or tissue type.
        </p>
        <p>
          The deluge of data from these deep sequencing experiments has brought about data
          management and analysis challenges, for which there are no current commercially available
          solutions. The Schmidt Academy is working with the Gradinaru lab on new software system
          that will accelerate streamline and accelerate research using AAVs.
        </p>
      </Typography>
    </Box>
  );
}

function LoginView() {
  return Base(<Login />);
}

function TaskTableView() {
  return Base(<TaskTable />);
}

function TaskView({ match }) {
  return Base(<Task task_id={match.params.id} />);
}

function ExperimentView({ match }) {
  return Base(<Experiment experiment_id={match.params.id} />);
}

function SampleView({ match }) {
  return Base(<Sample sample_id={match.params.id} />);
}

function FASTQFileView({ match }) {
  return Base(<FASTQFile fastqfile_id={match.params.id} />);
}

function FASTQFileTableView() {
  return Base(<FASTQFileTable sample_id={null} />);
}

function ExperimentTableView() {
  return Base(<ExperimentTable />);
}

function SampleTableView() {
  const tableRef = React.createRef();
  return Base(<SampleTable tableRef={tableRef} experiment_id={null} />);
}

function RefSeqTableView() {
  return Base(<RefSeqTable />);
}

function RefSeqView({ match }) {
  return Base(<RefSeq refseq_id={match.params.id} />);
}

function VariantTypeView({ match }) {
  return Base(<VariantType varianttype_id={match.params.id} />);
}

function VariantView({ match }) {
  return Base(<Variant variant_id={match.params.id} />);
}

function RosettaRunView({ match }) {
  return Base(<RosettaRun rosettarun_id={match.params.id} />);
}

function SocialAuthCallbackView() {
  return Base(<SocialAuthCallback />);
}

function DashboardView() {
  return Base(<Dashboard />);
}

function PDBViewerView() {
  return <PDBViewer />;
}

function LiteMolView() {
  return <LiteMolViewer />;
}

function LogoutView() {
  auth.logout();
  return (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  );
}

class App extends React.Component {
  render() {
    const notifyRef = React.createRef();
    const onClickDismiss = key => () => {
      notifyRef.current.closeSnackbar(key);
    };
    return (
      <Router>
        <div>
          <SnackbarProvider
            ref={notifyRef}
            action={key => (
              <IconButton onClick={onClickDismiss(key)}>
                <CloseIcon style={{ fill: 'white' }} />
              </IconButton>
            )}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Route path="/" exact component={Index} />
            <PrivateRoute path="/dashboard" exact component={DashboardView} />
            <Route path="/login/" exact component={LoginView} />
            <Route path="/logout/" exact component={LogoutView} />
            <Route path="/callback/google/" exact component={SocialAuthCallbackView} />
            <PrivateRoute path="/task_table" component={TaskTableView} />
            <PrivateRoute path="/fastqfile_table" component={FASTQFileTableView} />
            <PrivateRoute path="/experiment_table" component={ExperimentTableView} />
            <PrivateRoute path="/sample_table" component={SampleTableView} />
            <PrivateRoute path="/refseq_table" component={RefSeqTableView} />
            <PrivateRoute path="/task/:id/" component={TaskView} />
            <PrivateRoute path="/sample/:id/" component={SampleView} />
            <PrivateRoute path="/experiment/:id/" component={ExperimentView} />
            <PrivateRoute path="/fastqfile/:id/" component={FASTQFileView} />
            <PrivateRoute path="/refseq/:id/" component={RefSeqView} />
            <PrivateRoute path="/varianttype/:id/" component={VariantTypeView} />
            <PrivateRoute path="/variant/:id/" component={VariantView} />
            <PrivateRoute path="/rosettarun/:id/" component={RosettaRunView} />
            <PrivateRoute path="/pdbview/" component={PDBViewerView} />
            <PrivateRoute path="/litemolview/" component={LiteMolView} />
          </SnackbarProvider>
        </div>
      </Router>
    );
  }
}

export default hot(module)(App);
