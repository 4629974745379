import { Container, Paper, Typography } from '@material-ui/core/';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import HeatMap from 'react-heatmap-grid';

const no_legend = {
  display: false
};

/**
 * Generates a histogram plot from the histogram data, along with the bins
 * associated with the values.
 */
export default function HeatmapViz(props) {
  const theme = useTheme();
  const fill = theme.palette.primary.main;
  var xLabels = [];
  var yLabels = [];
  for (var i = 0; i < props.length; i++) {
    xLabels.push((i + 1).toString());
    yLabels.push((i + 1).toString());
  }
  var data = new Array(props.length).fill(0).map(() => new Array(props.length).fill(0));

  props.data.forEach((elem, idx) => {
    if (elem[0] == elem[1]) {
      data[elem[0]][elem[1]] = null;
    } else {
      data[elem[0]][elem[1]] = elem[2];
    }
  });

  return (
    <Paper className={props.className}>
      <Typography variant="h6">{props.title}</Typography>
      <Container>
        <HeatMap
          xLabels={xLabels}
          yLabels={yLabels}
          xLabelsLocation={'bottom'}
          xLabelWidth={50}
          data={data}
          cellRender={value => value && `${value.toFixed(3)}`}
          cellStyle={(background, value, min, max, data, x, y) => ({
            background: `rgba(0, 0, 0, ${1 - (max - value) / (max - min)})`,
            fontSize: '11px',
            color: '#fff'
          })}
        />
      </Container>
    </Paper>
  );
}
HeatmapViz.defaultProps = { height: 300 };
