import Cookies from 'js-cookie';

/**
 * A standard header for JSON POST requests
 */
export const JSONHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

/**
 * A configuration variable that states where the API for this application is
 * located.
 * TODO: relocate to configuration file.
 */
export const api_location = 'http://localhost:8000/api/v1';

/**
 * Generates a human-readable field name from the key. In particular,
 * underscores are removed and words are capitalized
 */
export function verbose_field_name(str) {
  // replace underscores with spaces.
  str = str.replace(new RegExp('_', 'g'), ' ');
  // capitalize words in string
  str = str.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
  return str;
}

/**
 * Converts a standard timestamp to a time since now
 */
export function timeSince(date) {
  const seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return `${interval} years`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes`;
  }
  return `${Math.floor(seconds)} seconds`;
}

/**
 * Checks whether the argument is a valid DNA string (only containing ATCGs)
 */
export function verify_ATCG(s) {
  return s.matches('[atcgATCG]+');
}

/**
 * Trims whitespace off of the ends of a string
 */
export function trim(s) {
  return s.replace(/^\s+|\s+$/g, '');
}

/**
 * Trims quotes off of the ends of a string
 *
 */
export function beautifyJSONString(s) {
  try {
    return JSON.stringify(JSON.parse(s), null, 2);
  } catch (e) {
    console.log(`JSON parsing failure: ${s}`);
  }
  return s;
}

export function histogram(data, size) {
  const { length } = data;

  let min = data[0];
  let max = data[1];

  for (var i = 0; i < length; i++) {
    const item = data[i];
    if (item < min) min = item;
    else if (item > max) max = item;
  }

  const bins = Math.ceil((max - min + 1) / size);

  const histogram = new Array(bins);

  for (var i = 0; i < bins; i++) histogram[i] = 0;

  for (var i = 0; i < length; i++) histogram[Math.floor((data[i] - min) / size)]++;

  // Standardize histogram to total frequency for this sample
  const total = histogram.reduce((a, b) => a + b, 0);
  var final_hist = histogram.map(function (element) {
    return element / total;
  });

  return final_hist;
}

/**
 * Checks response for errors and raises an Exception if the request failed
 * in some way. This method is used primarily to genericize errors and
 * properly wrap them in the right Exception to be handled higher in the
 * calling structure.
 */
export function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const getAccessToken = () => Cookies.get('auth_key');
export const isAuthenticated = () => !!getAccessToken();

/**
 * Generates the REST HTTP POST content, given payload. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_post_request(payload) {
  return {
    method: 'POST',
    headers: JSONHeader,
    body: JSON.stringify(payload)
  };
}

/**
 * Generates the REST HTTP POST content, given payload. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_get_request() {
  return {
    method: 'GET',
    headers: JSONHeader
  };
}

/**
 * Checks whether the string provided is composed of acceptable DNA nucleotides
 */
export function is_dna(s) {
  return /^[ATCG]+$/.test(s);
}

export function validate_sample(sample) {
  const index_dna = is_dna(sample.index_i5) && is_dna(sample.index_i7);
  return index_dna;
}

/**
 * Validate an insertion for VariantType
 * TODO: actually validate, stub currently returning true
 * @param {} ins
 * */
export function validate_site(ins) {
  return true;
}

/**
 * Validate an insertion for VariantType
 * TODO: actually validate, stub currently returning true
 * @param {} sub
 */
export function validate_substitution(sub) {
  return true;
}

export function resolve_index(idx_field) {
  idx_field = trim(idx_field);
  if (idx_field in NEB_indexes) {
    return NEB_indexes[idx_field];
  }
  return idx_field;
}

/**
 * Parses tabular input from Excel or similar into new samples where the
 * columns are sample_name, index_i7, index_i5
 */
export function parse_sample_input(old_samples, content) {
  const lines = content.split('\n');
  if (old_samples != null && Array.isArray(old_samples)) {
    var samples = old_samples.slice(0);
  } else {
    var samples = [];
  }
  const sample_names = new Set(samples.map(sample => sample.name));
  for (let i = 0; i < lines.length; i++) {
    // split by tab
    const fields = lines[i].split('\t');
    if (fields.length === 3 || fields.length === 2) {
      const sample = {};
      sample.name = trim(fields[0]);
      // validate ATCG
      sample.index_i7 = resolve_index(fields[1]);
      if (fields.length === 3) {
        sample.index_i5 = resolve_index(fields[2]);
      }
      if (validate_sample(sample) && !sample_names.has(sample.name)) {
        samples.push(sample);
        sample_names.add(sample.name);
      }
    } else {
      // TODO throw exception
    }
  }
  return samples;
}

const TRANSLATION_TABLE = {
  ATA: 'I',
  ATC: 'I',
  ATT: 'I',
  ATG: 'M',
  ACA: 'T',
  ACC: 'T',
  ACG: 'T',
  ACT: 'T',
  AAC: 'N',
  AAT: 'N',
  AAA: 'K',
  AAG: 'K',
  AGC: 'S',
  AGT: 'S',
  AGA: 'R',
  AGG: 'R',
  CTA: 'L',
  CTC: 'L',
  CTG: 'L',
  CTT: 'L',
  CCA: 'P',
  CCC: 'P',
  CCG: 'P',
  CCT: 'P',
  CAC: 'H',
  CAT: 'H',
  CAA: 'Q',
  CAG: 'Q',
  CGA: 'R',
  CGC: 'R',
  CGG: 'R',
  CGT: 'R',
  GTA: 'V',
  GTC: 'V',
  GTG: 'V',
  GTT: 'V',
  GCA: 'A',
  GCC: 'A',
  GCG: 'A',
  GCT: 'A',
  GAC: 'D',
  GAT: 'D',
  GAA: 'E',
  GAG: 'E',
  GGA: 'G',
  GGC: 'G',
  GGG: 'G',
  GGT: 'G',
  TCA: 'S',
  TCC: 'S',
  TCG: 'S',
  TCT: 'S',
  TTC: 'F',
  TTT: 'F',
  TTA: 'L',
  TTG: 'L',
  TAC: 'Y',
  TAT: 'Y',
  TAA: '_',
  TAG: '_',
  TGC: 'C',
  TGT: 'C',
  TGA: '_',
  TGG: 'W'
};
export function translate(s) {
  var aa = '';
  if (s.length % 3 != 0) {
    return '';
  }
  for (var i = 0; i < s.length; i += 3) {
    aa += TRANSLATION_TABLE[s.substring(i, i + 3)];
  }
  return aa;
}

/**
 * Returns nucleotide sequence for NEB-style indexes
 */
export const NEB_indexes = {
  i501: 'TATAGCCT',
  i502: 'ATAGAGGC',
  i503: 'CCTATCCT',
  i504: 'GGCTCTGA',
  i505: 'AGGCGAAG',
  i506: 'TAATCTTA',
  i507: 'CAGGACGT',
  i508: 'GTACTGAC',
  i701: 'ATTACTCG',
  i702: 'TCCGGAGA',
  i703: 'CGCTCATT',
  i704: 'GAGATTCC',
  i705: 'ATTCAGAA',
  i706: 'GAATTCGT',
  i707: 'CTGAAGCT',
  i708: 'TAATGCGC',
  i709: 'CGGCTATG',
  i710: 'TCCGCGAA',
  i711: 'TCTCGCGC',
  i712: 'AGCGATAG'
};

export function getKeyByValue(object, value) {
  var val = Object.keys(object).find(key => object[key] === value);
  return val === undefined ? "" : val;
}

/**
 * Format a number of bytes into a human readable string.
 * @param {int} a number of bytes
 * @param {int} b digits of precision
 */
function formatBytes(a, b) {
  if (0 == a) return '0 Bytes';
  var c = 1024,
    d = b || 2,
    e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
}

/**
 * Format a number of bytes to a human readable string
 * @param {int} n
 */
export function human_bytesize(n) {
  return formatBytes(n, 2);
}

export function hostname(s) {
  try {
    let url = new URL(s);
    return url.hostname;
  } catch (e) {
    return s;
  }
}
