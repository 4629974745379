import { Button, Card, CardContent, CardHeader, Checkbox, Divider, Grid, Input, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import FilterIcon from '@material-ui/icons/FilterList';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as styles from '../util/style.jsx';


function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

class TransferList extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckedLeft = this.handleCheckedLeft.bind(this);
    this.handleCheckedRight = this.handleCheckedRight.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.numberOfChecked = this.numberOfChecked.bind(this);
  }

  handleToggle(value) {
    const currentIndex = this.props.checked.indexOf(value);
    const newChecked = [...this.props.checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.props.onCheckedChange(newChecked);
  }

  numberOfChecked(items) {
    return intersection(this.props.checked, items).length;
  }

  handleToggleAll(items) {
    if (this.numberOfChecked(items) === items.length) {
      this.props.onCheckedChange(not(this.props.checked, items));
    } else {
      this.props.onCheckedChange(union(this.props.checked, items));
    }
  }

  handleCheckedLeft() {
    const newSelected = this.props.selected.filter(
      option => this.props.checked.indexOf(option) == -1
    );
    const toMove = this.props.selected.filter(option => this.props.checked.indexOf(option) != -1);
    this.props.onSelectionChange(newSelected);
    this.handleToggleAll(toMove);
  }

  handleCheckedRight() {
    const toMove = not(this.props.options, this.props.selected).filter(
      option => this.props.checked.indexOf(option) != -1
    );
    const newSelected = this.props.selected.concat(toMove);
    this.props.onSelectionChange(newSelected);
    this.handleToggleAll(toMove);
  }

  listComponent(title, filter_name, items) {
    items = items.filter(item => item.name.includes(this.props[filter_name]));
    items.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    const { classes } = this.props;
    return (
      <Card>
        <CardContent className={classes.cardHeader}>
          <Input
            id={filter_name}
            name={filter_name}
            startAdornment={
              <InputAdornment position="start">
                <FilterIcon />
              </InputAdornment>
            }
            onChange={this.props.onChange}
            value={this.props[filter_name]}
            variant="outlined"
            fullWidth
          />
        </CardContent>
        <Divider />
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Checkbox
              onClick={() => this.handleToggleAll(items)}
              checked={this.numberOfChecked(items) === items.length && items.length !== 0}
              indeterminate={
                this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ 'aria-label': 'all items selected' }}
            />
          }
          title={title}
          subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List className={classes.list} dense component="div" role="list">
          {items.map(value => {
            const labelId = `transfer-list-item-${value}-label`;
            return (
              <ListItem
                key={value.name}
                role="listitem"
                button
                onClick={() => this.handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={this.props.checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} justify="center" alignItems="center" className={classes.root}>
        <Grid item xs={5}>
          {this.listComponent(
            'Choices',
            'choices_filter',
            not(this.props.options, this.props.selected)
          )}
        </Grid>
        <Grid item xs={1}>
          <Grid container direction="column" alignItems="center">
            <Tooltip title="Select checked">
              <Button
                variant="outlined"
                size="small"
                onClick={this.handleCheckedRight}
                aria-label="move selected right"
                className={classes.button}
              >
                &gt;
              </Button>
            </Tooltip>
            <Tooltip title="Unselect checked">
              <Button
                variant="outlined"
                size="small"
                onClick={this.handleCheckedLeft}
                aria-label="move selected left"
                className={classes.button}
              >
                &lt;
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {this.listComponent('Chosen', 'chosen_filter', this.props.selected)}
        </Grid>
      </Grid>
    );
  }
}

TransferList.defaultProps = {
  selected: []
};
export default withSnackbar(withStyles(styles.transfer_list_styles)(TransferList));
