import LiteMol from 'litemol';
import React from 'react';

class LiteMolViewer extends React.Component {
  constructor(props) {
    super(props);
    this.target = React.createRef();
  }
  componentDidMount() {
    console.dir(LiteMol);
    /*
    const viewer = LiteMol.Viewer.createInstance({
      target: this.target
    });
    */
  }
  render() {
    return (
      <div
        style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
        ref={ref => (this.target = ref)}
      />
    );
  }
}

export { LiteMolViewer };

