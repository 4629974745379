import { Typography } from '@material-ui/core/';
import React from 'react';
import { dna_colors } from '../../util/style.jsx';

/**
 * Shows a string of basepairs (ATCG) as colored monospace strings.
 * Optionally shows DNA as triplets (codons) (triplet = true)
 * TODO support different ORFs
 */
export function DNA(props) {
  let output = [];
  let orf = 2;
  const key_limit = 50;

  // compute a relatively unique key for an element
  var calKey = props.seq.length < key_limit ? props.seq : props.seq.substring(0, key_limit);
  for (let i = 0; i < props.seq.length; i++) {
    let base = props.seq.charAt(i);

    output.push(
      <span key={calKey + '_' + i.toString()} style={{ color: dna_colors[base] }}>
        {base}
      </span>
    );
    if (props.triplet && i % 3 == orf) {
      output.push(
        <span key={calKey + '_sp_' + i.toString()} style={{ color: dna_colors[base] }}>
          {' '}
        </span>
      );
    }
  }
  return (
    <Typography component="div">
      <span key={calKey}>{output}</span>
    </Typography>
  );
}
DNA.defaultProps = {
  triplet: false
};
