import { Container, Table, TableBody, TableCell, TableRow } from '@material-ui/core/';
import React from 'react';
import { DNA } from './DNA';

export function LargeSequence(props) {
  // how many rows do we need? ( Math.floor(seq.length / (line_size * block_size)))

  // break sequence into blocks of size block_size using RegExp (note we're using template strings, so >ES6)
  let re = new RegExp(`.{1,${props.block_size}}`, 'g');
  let blocks = props.seq.match(re);
  let rows = [];

  // TODO replace with withStyles
  let styles = {
    container: {
      overflowX: 'auto'
    },
    tablecell: {
      whiteSpace: 'nowrap',
      display: 'block',
      overflow: 'hidden'
    }
  };

  for (var i = 0; i < blocks.length; i += props.line_size) {
    rows.push(
      <TableRow key={'row' + i}>
        <TableCell style={styles.tablecell} key={`leader${i}`}>
          {i * props.block_size + 1}
        </TableCell>
        {blocks.slice(i, i + props.line_size).map((elem, idx) => (
          <TableCell key={idx + i}>
            <span style={styles.tablecell}>
              <DNA seq={elem} />
            </span>
          </TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <Container style={styles.container}>
      <Table>
        <TableBody>{rows}</TableBody>
      </Table>
    </Container>
  );
}

LargeSequence.defaultProps = {
  type: 'DNA',
  line_size: 5,
  block_size: 10
};
