import { IconButton, Link } from '@material-ui/core/';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import React from 'react';

/**
 * Shows a link passed in as a filename hyperlinked to the resource to be
 * downloaded
 */
export function Download(props) {
  if (props.url) {
    if (props.icon == true) {
      return (
        <IconButton href={props.url} aria-label="view">
          <DownloadIcon />
        </IconButton>
      );
    } else {
      var fname = props.url
        .split('/')
        .pop()
        .split('#')[0]
        .split('?')[0];
      return (
        <Link href={props.url} aria-label="view">
          {fname}
        </Link>
      );
    }
  }
  return <React.Fragment></React.Fragment>;
}

Download.defaultProps = {
  icon: false
};
