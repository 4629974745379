import { Box, Chip } from '@material-ui/core/';
import { red } from '@material-ui/core/colors/';
import React from 'react';
import { AA } from './AA';

export function AminoAcidVariant(props) {
  if (props.variant == null) {
    return (
      <Chip
        variant="outlined"
        size="small"
        style={{ color: red['500'], borderWidth: '1px', borderColor: red['500'] }}
        label="invalid"
      />
    );
  }
  return (
    <Box display="flex">
      <Chip
        variant="outlined"
        size="small"
        color="primary"
        label={props.reference.name + ':p' + Math.floor(props.variant_type.sites[0].start / 3)}
      />
      &nbsp;
      <AA seq={props.variant} />
      &nbsp;
      <Chip
        variant="outlined"
        size="small"
        color="primary"
        label={props.reference.name + ':p' + (Math.ceil(props.variant_type.sites[0].stop / 3) + 1)}
      />
    </Box>
  );
}
