import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, Step, StepContent, StepLabel, Stepper, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab/';
import { Form, Formik } from 'formik';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DNA } from './decorators/DNA';


class PureImportSamplesStepForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new_samples: [],
      activeStep: 0
    };
    this.handleAddSamples = this.handleAddSamples.bind(this);
    this.handlePaste = event => {
      event.clipboardData.items[0].getAsString(text =>
        this.setState({
          new_samples: util.parse_sample_input(this.state.new_samples, text)
        })
      );
    };
  }

  nextStep() {
    const temp = this.state.activeStep + 1;
    this.setState({
      activeStep: temp
    });
  }

  async handleAddSamples() {
    try {
      // pack parent_experiment into samples to be imported, critical for proper
      // object organization
      if (this.state.new_samples.length > 0) {
        const samples_clone = this.state.new_samples.slice(0);
        for (let i = 0; i < samples_clone.length; i++) {
          samples_clone[i].parent_experiment = this.props.experiment_id;
        }
        const samples = await api.createSamples(samples_clone, this.props.enqueueSnackbar);
        this.setState({
          new_samples: samples
        });
        this.nextStep();
      }
    } catch (e) {
      console.log(e);
      // TODO handle error, set message component
    }
  }

  async handleSetLibrary(library_uuid) {
    const samples_clone = this.state.new_samples.slice(0);
    for (let i = 0; i < samples_clone.length; i++) {
      if (samples_clone[i].uuid != library_uuid) {
        if (library_uuid == 'None') {
          samples_clone[i].virus_library_sample = null;
        } else {
          samples_clone[i].virus_library_sample = library_uuid;
        }
        // TODO replace individual UPDATE with better view on DRF to prevent n requests
        await api.update('sample', samples_clone[i], this.props.enqueueSnackbar);
      }
    }
    this.nextStep();
  }

  async handleImport(remote, samples) {
    try {
      // pack parent_experiment into samples to be imported, critical for proper
      // object organization
      const sample_ids = samples.map(s => s.uuid);
      const payload = { ...remote };
      payload.samples = sample_ids;
      await api.importSamples(payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    } catch (e) {
      // TODO handle error, set message component
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog fullWidth maxWidth="md" onClose={this.props.handleClose} open={this.props.open}>
          <DialogTitle id="form-dialog-title">Import Samples</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Import samples from a remote source (Jumpgate, the City of Hope, etc.) by providing
              sample name, the i7 index and optionally the i5 index (in base pair format). You can
              copy and paste the table from Excel.
            </DialogContentText>
            <Stepper activeStep={this.state.activeStep} orientation="vertical">
              <Step>
                <StepLabel>Add Samples</StepLabel>
                <StepContent>
                  <Box className={classes.table} onPaste={this.handlePaste}>
                    <MaterialTable
                      columns={[
                        {
                          title: 'Name',
                          field: 'name'
                        },
                        {
                          title: 'I7 Index',
                          field: 'index_i7',
                          render: obj => <DNA seq={obj.index_i7} />
                        },
                        {
                          title: 'I5 Index',
                          field: 'index_i5',
                          render: obj => <DNA seq={obj.index_i5} />
                        }
                      ]}
                      data={this.state.new_samples}
                      title="New Samples"
                      options={{
                        search: false
                      }}
                      editable={{
                        isEditable: rowData => true,
                        isDeletable: rowData => true,
                        onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                if (!util.validate_sample(newData)) {
                                  reject();
                                  return;
                                }
                                const data = this.state.new_samples;
                                data.push(newData);
                                this.setState({
                                  new_samples: data
                                });
                                resolve();
                              }
                              resolve();
                            }, 10);
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                if (!util.validate_sample(newData)) {
                                  reject();
                                }
                                const data = this.state.new_samples;
                                const index = data.indexOf(oldData);
                                data[index] = newData;
                                this.setState({
                                  new_samples: data
                                });
                              }
                              resolve();
                            }, 10);
                          }),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = this.state.new_samples;
                                const index = data.indexOf(oldData);
                                data.splice(index, 1);
                                this.setState({
                                  new_samples: data
                                });
                              }
                              resolve();
                            }, 10);
                          })
                      }}
                    />
                  </Box>
                  <Button onClick={this.handleAddSamples}>Add</Button>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Set the starting (virus) library</StepLabel>
                <StepContent>
                  <Paper className={classes.root}>
                    <Formik
                      initialValues={{ virus_library: '' }}
                      onSubmit={async values => {
                        await this.handleSetLibrary(values.virus_library);
                      }}
                    >
                      {props => {
                        const {
                          setFieldValue,
                          values,
                          touched,
                          errors,
                          dirty,
                          isSubmitting,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          handleReset
                        } = props;
                        var all_samples = [];
                        all_samples = all_samples.concat(this.props.sample_names);
                        all_samples = all_samples.concat(this.state.new_samples);
                        all_samples.sort((a, b) => a.name.localeCompare(b.name));
                        return (
                          <Form>
                            <FormControl>
                              <Autocomplete
                                id="virus_library"
                                name="virus_library"
                                options={all_samples}
                                getOptionLabel={option => option.name}
                                style={{ width: 300 }}
                                value={values.virus_library}
                                onChange={(_, value) => setFieldValue('virus_library', value)}
                                renderInput={params => (
                                  <TextField {...params} variant="outlined" fullWidth />
                                )}
                              />
                            </FormControl>
                            <Button type="submit" disabled={isSubmitting}>
                              Set
                            </Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Paper>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Remote Connection Information</StepLabel>
                <StepContent>
                  <Formik
                    initialValues={{
                      protocol: 'http',
                      remote_path: '',
                      username: '',
                      password: ''
                    }}
                    onSubmit={async values => {
                      await this.handleImport(values, this.state.new_samples);
                    }}
                  >
                    {props => {
                      const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset
                      } = props;

                      return (
                        <Form>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Protocol</FormLabel>
                            <RadioGroup
                              aria-label="protocol"
                              name="protocol"
                              value={values.protocol}
                              onChange={this.handleChange}
                              row
                            >
                              <FormControlLabel
                                value="http"
                                control={<Radio color="primary" />}
                                label="HTTP(s)"
                              />
                              <FormControlLabel
                                value="ftp"
                                control={<Radio color="primary" />}
                                label="FTP"
                              />
                              <FormControlLabel
                                value="sftp"
                                control={<Radio color="primary" />}
                                label="SFTP/SSH"
                              />
                            </RadioGroup>
                          </FormControl>
                          <TextField
                            label="Remote Path"
                            onChange={handleChange}
                            value={values.remote_path}
                            fullWidth
                            autoFocus
                            name="remote_path"
                            margin="normal"
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                label="Username"
                                onChange={handleChange}
                                value={values.username}
                                fullWidth
                                autoFocus
                                name="username"
                                margin="normal"
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                label="Password"
                                onChange={handleChange}
                                value={values.password}
                                fullWidth
                                autoFocus
                                name="password"
                                margin="normal"
                              />
                            </Grid>
                          </Grid>
                          <Button type="submit" disabled={isSubmitting}>
                            Import
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </StepContent>
              </Step>
            </Stepper>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const ImportSamplesStepForm = withSnackbar(
  withStyles(styles.form_styles)(PureImportSamplesStepForm)
);

export { ImportSamplesStepForm };

