import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Redirect } from 'react-router-dom';
import * as auth from '../api/auth.js';
import * as styles from '../util/style.jsx';
import Loading from './Loading';

/**
 * Component that handles OAuth2 Callbacks
 */
class SocialAuthCallbackComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'loading'
    };
  }

  parseToken() {
    const hash = window.location.hash;
    const fakesearch = '?' + hash.slice(1);
    const urlparams = new URLSearchParams(fakesearch);
    if (urlparams.has('access_token')) {
      const access_token = urlparams.get('access_token');
      return access_token;
    }
    return null;
  }

  async componentDidMount() {
    const access_token = this.parseToken();
    if (access_token != null) {
      var resp = await auth.exchange(access_token, this.props.enqueueSnackbar);
      if (resp == true) {
        this.setState({
          status: 'success'
        });
      } else {
        this.setState({
          status: 'error'
        });
      }
    } else {
      this.setState({
        status: 'error'
      });
    }
  }

  render() {
    return (
      <>
        {this.state.status == 'loading' && <Loading />}
        {this.state.status == 'success' && <Redirect to={'/dashboard'} />}
        {this.state.status == 'error' && <Redirect to={'/login'} />}
      </>
    );
  }
}

const SocialAuthCallback = withSnackbar(
  withStyles(styles.detail_styles)(SocialAuthCallbackComponent)
);

export { SocialAuthCallback };

