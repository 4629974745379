import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Form, Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';

class SampleForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    var contentState = null;
    var blocksFromHTML = null;
    if (this.props.sample.description != null) {
      blocksFromHTML = convertFromHTML(this.props.sample.description);
      contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
    }
    this.state = {
      initialValues: {
        ...this.props.sample,
        editorState:
          this.props.sample.description == null
            ? new EditorState.createEmpty()
            : new EditorState.createWithContent(contentState)
      }
    };
  }

  async componentDidMount() { }

  async submitForm(values, { resetForm }) {
    // convert editorState into HTML markup
    const markup = draftToHtml(convertToRaw(values.editorState.getCurrentContent()));
    var payload = JSON.parse(JSON.stringify(values));
    payload.description = markup;
    delete payload.editorState;
    // TODO Get description content from Editor
    if (this.props.mode == 'create') {
      await api.create('sample', payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    } else {
      await api.update('sample', payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    }
    this.props.callback();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Formik initialValues={this.state.initialValues} onSubmit={this.submitForm}>
          {props => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleReset
            } = props;
            return (
              <>
                <Dialog
                  fullWidth={true}
                  maxWidth="md"
                  open={this.props.open}
                  onClose={this.props.handleClose}
                >
                  <Form onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">
                      {this.props.mode == 'create' ? 'Create' : 'Update'} Experiment
                    </DialogTitle>
                    <DialogContent>
                      <FormControl fullWidth>
                        <TextField
                          className={classes.margin}
                          required
                          id="outlined-required"
                          label="Name"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          margin="normal"
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <FormLabel style={{ marginTop: '10px' }}>Description</FormLabel>
                        <Box style={{ marginTop: '10px' }}>
                          <Editor
                            editorState={values.editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={editorState =>
                              setFieldValue('editorState', editorState)
                            }
                          />
                        </Box>
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button type="submit" disabled={isSubmitting} color="primary">
                        {this.props.mode == 'create' ? 'Create' : 'Update'}
                      </Button>
                    </DialogActions>
                  </Form>
                </Dialog>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}
SampleForm.defaultProps = {
  sample: { name: '' },
  callback: Function.prototype
};

export default withSnackbar(withStyles(styles.form_styles)(SampleForm));
