import { Button, Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import TransferList from './TransferList';

class AlignSamplesForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleAlign = this.handleAlign.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.initialValues = {
      samples: [],
      checked: [],
      choices_filter: '',
      chosen_filter: ''
    };
  }

  async handleAlign(remote, samples) {
    // pack parent_experiment into samples to be imported, critical for proper
    // object organization
    const sample_ids = samples.map(s => s.uuid);
    const payload = { samples: sample_ids };
    await api.alignSamples(payload, this.props.enqueueSnackbar, {
      success_notify: true
    });
  }

  async submitForm(values, { setSubmitting, setErrors, setStatus, resetForm }) {
    setSubmitting(true);
    await this.handleAlign(values.remote, values.samples);
    await this.props.callback();
    setSubmitting(false);
    resetForm(this.initialValues);
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik initialValues={this.initialValues} onSubmit={this.submitForm}>
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            setFieldValue,
            setTouched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = props;
          return (
            <Form>
              <TransferList
                loading={this.props.samples}
                options={this.props.samples}
                selected={values.samples}
                checked={values.checked}
                choices_filter={values.choices_filter}
                chosen_filter={values.chosen_filter}
                onSelectionChange={value => setFieldValue('samples', value)}
                onCheckedChange={value => setFieldValue('checked', value)}
                onChange={handleChange}
              />
              <Grid
                className={classes.margin}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                    Align
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withSnackbar(withStyles(styles.form_styles)(AlignSamplesForm));
