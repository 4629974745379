import { Box, Button, Grid } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DNA } from './decorators/DNA';

class AddSamplesForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddSamples = this.handleAddSamples.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.initialValues = { samples: [] };
  }

  async handleAddSamples(samples) {
    // pack parent_experiment into samples to be imported, critical for proper
    // object organization
    if (samples.length > 0) {
      const samples_clone = samples.slice(0);
      for (let i = 0; i < samples_clone.length; i++) {
        samples_clone[i].parent_experiment = this.props.experiment_id;
      }
      const gen_samples = await api.createSamples(samples_clone, this.props.enqueueSnackbar, {
        success_notify: false
      });
      return gen_samples;
    }
    return [];
  }

  handlePaste(event, samples, setFieldValue) {
    event.clipboardData.items[0].getAsString(text =>
      setFieldValue('samples', util.parse_sample_input(samples, text))
    );
  }

  async submitForm(values, { setSubmitting, setErrors, setStatus, resetForm }) {
    setSubmitting(true);
    await this.handleAddSamples(values.samples);
    await this.props.callback();
    setSubmitting(false);
    resetForm(this.initialValues);
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik initialValues={this.initialValues} onSubmit={this.submitForm}>
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            setFieldValue,
            setTouched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = props;
          return (
            <Form>
              <Box
                className={classes.table}
                onPaste={event => this.handlePaste(event, values.samples, setFieldValue)}
              >
                <MaterialTable
                  columns={[
                    {
                      title: 'Name',
                      field: 'name'
                    },
                    {
                      title: 'I7 Index',
                      field: 'index_i7',
                      render: rowData => <DNA seq={rowData.index_i7} />
                    },
                    {
                      title: 'I5 Index',
                      field: 'index_i5',
                      render: rowData => <DNA seq={rowData.index_i5} />
                    }
                  ]}
                  data={values.samples}
                  title="New Samples"
                  options={{
                    search: false
                  }}
                  actions={[
                    {
                      icon: 'clear',
                      tooltip: 'Clear All Samples',
                      isFreeAction: true,
                      onClick: event => setFieldValue('samples', [])
                    }
                  ]}
                  editable={{
                    isEditable: rowData => true,
                    isDeletable: rowData => true,
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            if (!util.validate_sample(newData)) {
                              reject();
                              return;
                            }
                            const data = values.samples;
                            data.push(newData);
                            setFieldValue('samples', data);
                            resolve();
                          }
                          resolve();
                        }, 10);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            if (!util.validate_sample(newData)) {
                              reject();
                            }
                            const data = values.samples;
                            const index = data.indexOf(oldData);
                            data[index] = newData;
                            setFieldValue('samples', data);
                          }
                          resolve();
                        }, 10);
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = values.samples;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            setFieldValue('samples', data);
                          }
                          resolve();
                        }, 10);
                      })
                  }}
                />
              </Box>
              <Grid
                className={classes.margin}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withSnackbar(withStyles(styles.form_styles)(AddSamplesForm));
