import { AppBar, Button, IconButton, InputBase, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Cookies from 'js-cookie';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import * as styles from '../util/style.jsx';
import MainDrawer from './MainDrawer';

/**
 * Shows a navigation bar at the top of the webpage
 */
class NavBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      menuOpen: false,
      anchor: null,
      user: Cookies.getJSON('user')
    };
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleMenuOpen = this.handleMenuOpen.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.logout = this.logout.bind(this);
  }

  toggleDrawer(open) {
    if (open == null) {
      this.setState({
        drawerOpen: !this.state.drawerOpen
      });
    } else {
      this.setState({
        drawerOpen: open
      });
    }
  }

  handleMenuOpen(event) {
    this.setState({
      anchor: event.currentTarget,
      menuOpen: true
    });
  }

  handleMenuClose() {
    this.setState({
      anchor: null,
      menuOpen: false
    });
  }

  logout() {
    this.props.history.push('/logout/');
    this.handleMenuClose();
  }

  authenticated_links() {
    const { classes } = this.props;
    if (this.props.authenticated) {
      return (
        <React.Fragment>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>

          <Button component={RouterLink} to={'/dashboard'} color="inherit">
            Dashboard
          </Button>
          {this.state.user == null || !this.state.user.hasOwnProperty('first_name') ? (
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={this.handleMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          ) : (
              <Button onClick={this.handleMenuOpen} color="inherit">
                <AccountCircle style={{ paddingRight: '4px' }} />
                {this.state.user.first_name}
              </Button>
            )}
          <Menu
            id="menu-appbar"
            getContentAnchorEl={null}
            anchorEl={this.state.anchor}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={this.state.menuOpen}
            onClose={this.handleMenuClose}
          >
            <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={this.logout}>Logout</MenuItem>
          </Menu>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button color="inherit" component={RouterLink} to="/login/">
          Login
        </Button>
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => this.toggleDrawer()}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              PEAT
            </Typography>
            {this.authenticated_links()}
          </Toolbar>
        </AppBar>
        <MainDrawer open={this.state.drawerOpen} toggleDrawer={this.toggleDrawer} />
      </div>
    );
  }
}

const NavBar = withRouter(withSnackbar(withStyles(styles.menu_styles)(NavBarComponent)));
export { NavBar };

