import { Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Form, Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import TransferList from './TransferList';

class EnrichmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleCalculateEnrichment = this.handleCalculateEnrichment.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.aligned_samples = this.props.samples.filter(sample => sample.aligned);
    this.initialValues = {
      virus_library_sample: {},
      starting_library_enabled: false,
      samples: [],
      choices_filter: '',
      chosen_filter: '',
      checked: []
    };
  }

  async handleUpdateSamples(samples, virus_library_sample) {
    var promises = samples.map(sample =>
      api.update(
        'sample',
        { uuid: sample.uuid, starting_sample: virus_library_sample.uuid },
        this.props.enqueueSnackbar
      )
    );
    await Promise.all(promises);
  }

  async handleCalculateEnrichment(samples) {
    await api.calculateEnrichment(
      samples.map(sample => sample.uuid),
      this.props.enqueueSnackbar,
      {
        success_notify: true
      }
    );
  }

  async submitForm(values, { setSubmitting, setErrors, setStatus, resetForm }) {
    setSubmitting(true);
    if (values.starting_library_enabled) {
      await this.handleUpdateSamples(values.samples, values.virus_library_sample);
    }
    await this.handleCalculateEnrichment(values.samples);
    await this.props.callback();
    setSubmitting(false);
    resetForm(this.initialValues);
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik initialValues={this.initialValues} onSubmit={this.submitForm}>
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            setFieldValue,
            setTouched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = props;
          return (
            <Form>
              <TransferList
                loading={this.props.samples}
                options={this.props.samples}
                selected={values.samples}
                checked={values.checked}
                choices_filter={values.choices_filter}
                chosen_filter={values.chosen_filter}
                onSelectionChange={value => setFieldValue('samples', value)}
                onCheckedChange={value => setFieldValue('checked', value)}
                onChange={handleChange}
              />
              <Grid className={classes.margin} fullWidth>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="starting_library_enabled"
                        checked={values.starting_library_enabled}
                        onChange={(_, value) =>
                          setFieldValue(
                            'starting_library_enabled',
                            !values.starting_library_enabled
                          )
                        }
                        value="starting_library_enabled"
                      />
                    }
                    label="Set Starting Library"
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <Autocomplete
                      className={classes.margin}
                      id="virus_library_sample"
                      name="virus_library_sample"
                      options={this.aligned_samples}
                      getOptionLabel={option => option.name}
                      value={values.virus_library_sample}
                      onChange={(_, value) => setFieldValue('virus_library_sample', value)}
                      disabled={!values.starting_library_enabled}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Starting Library"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                className={classes.margin}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button variant="contained" onClick={handleSubmit} disabled={isSubmitting}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withSnackbar(withStyles(styles.form_styles)(EnrichmentForm));
