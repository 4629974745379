import { Typography } from '@material-ui/core/';
import React from 'react';
import { aa_colors } from '../../util/style.jsx';

/**
 * Shows a string of amino acids as colored monospace strings
 */
export function AA(props) {
  if (props.seq == '<invalid>') {
    return props.seq;
  }
  let output = [];
  for (let i = 0; i < props.seq.length; i++) {
    let base = props.seq.charAt(i);
    output.push(
      <span key={i} style={{ color: base in aa_colors ? aa_colors[base] : aa_colors['Others'] }}>
        {base}
      </span>
    );
  }
  return (
    <Typography component="div">
      <span>{output}</span>
    </Typography>
  );
}
