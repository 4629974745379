import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import renderHTML from 'react-render-html';
import { Link as RouterLink } from 'react-router-dom';
import Websocket from 'react-websocket';
import * as api from '../api/api.js';
import * as common from '../api/common.js';
import * as styles from '../util/style.jsx';
import AddSamplesForm from './AddSamplesForm';
import AlignSamplesForm from './AlignSamplesForm';
import { DateTime } from './decorators/DateTime';
import { DetailView } from './DetailView';
import EnrichmentForm from './EnrichmentForm';
import ExperimentForm from './ExperimentForm';
import ImportSamplesForm from './ImportSamplesForm';
import { ImportSamplesStepForm } from './ImportSamplesStepForm';
import JoinForm from './JoinForm';
import Loading from './Loading';
import { SampleTable } from './SampleTable';

/**
 * Component that renders a detailed view of the Experiment
 */
class ExperimentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      experiment: null,
      sample_names: null,
      operation: 'add',
      importSamplesOpen: false,
      editFormOpen: false
    };
    this.detail_fields = [
      {
        title: 'UUID',
        field: 'uuid',
        render: obj => obj.uuid
      },
      {
        title: 'Description',
        field: 'description',
        render: obj => obj.description != null && <Box>{renderHTML(obj.description)}</Box>
      },
      {
        title: 'Variant Type',
        field: 'variant_type',
        render: obj => (
          <RouterLink to={`/varianttype/${obj.variant_type}/`} aria-label="view">
            Link
          </RouterLink>
        )
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: obj => <DateTime timestamp={obj.time_created} />
      }
    ];

    this.operationInitialValues = { selected: [], checked: [] };

    this.socketRef = React.createRef();
    this.model_title = obj => obj.name;
    this.model_name = 'Experiment';
    this.tableRef = React.createRef();
    this.handleImportSamplesOpen = this.handleImportSamplesOpen.bind(this);
    this.handleImportSamplesClose = this.handleImportSamplesClose.bind(this);
    this.handleEditFormOpen = this.handleEditFormOpen.bind(this);
    this.handleEditFormClose = this.handleEditFormClose.bind(this);
    this.handleGetExperiment = this.handleGetExperiment.bind(this);
    this.handleGetSampleNames = this.handleGetSampleNames.bind(this);
  }

  async componentDidMount() {
    const experiment_get_promise = this.handleGetExperiment();
    const sample_name_promise = this.handleGetSampleNames();
    await Promise.all([experiment_get_promise, sample_name_promise]);
    api.send_watch(this.socketRef, 'Experiment', [this.props.experiment_id], ['name']);
  }

  handleSocketUpdate(data) {
    const merged = api.merge_update(data, this.state.experiment);
    this.setState({ experiment: merged });
  }

  async handleGetExperiment() {
    const experiment = await api.get(
      'experiment',
      this.props.experiment_id,
      this.props.enqueueSnackbar
    );
    if (experiment != null) {
      this.setState({
        experiment
      });
    }
  }

  handleEditFormOpen() {
    this.setState({
      editFormOpen: true
    });
  }

  handleEditFormClose() {
    this.setState({
      editFormOpen: false
    });
  }

  async handleGetSampleNames() {
    try {
      this.tableRef.current.onQueryChange();
      const query = { filters: [] };
      api.pack_filter(query, 'parent_experiment', this.props.experiment_id);
      api.pack_filter(query, 'no_page', '');
      const sample_names = await api.getList(
        'sample',
        query,
        ['uuid', 'name', 'aligned'],
        this.props.enqueueSnackbar
      );
      this.setState({
        sample_names
      });
    } catch (e) {
      console.log(e)
    }
  }

  async handleImportSamplesOpen() {
    this.setState({ importSamplesOpen: true });
  }

  handleImportSamplesClose() {
    this.setState({ importSamplesOpen: false });
    if (this.tableRef.current) {
      this.tableRef.current.onQueryChange();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Websocket
          ref={this.socketRef}
          url={common.watcher_socket_location}
          onMessage={this.handleSocketUpdate.bind(this)}
          reconnect
        />
        <ImportSamplesStepForm
          handleClose={this.handleImportSamplesClose}
          open={this.state.importSamplesOpen}
          sample_names={this.state.sample_names}
          experiment_id={this.props.experiment_id}
        />
        <Paper className={classes.root}>
          <DetailView
            model_name={this.model_name}
            model_title={this.model_title}
            detail_fields={this.detail_fields}
            model={this.state.experiment}
            edit={
              <Button variant="contained" onClick={this.handleEditFormOpen}>
                Edit
              </Button>
            }
          />
        </Paper>
        {this.state.experiment != null && (
          <>
            <ExperimentForm
              open={this.state.editFormOpen}
              handleClose={this.handleEditFormClose}
              experiment={this.state.experiment}
              mode="edit"
              callback={this.handleGetExperiment}
            />
            <Paper className={classes.root}>
              <Typography variant="h6">Sample Operations</Typography>
              <FormControl className={classes.topMargin} fullWidth>
                <InputLabel id="operations-label">Operation</InputLabel>
                <Select
                  labelId="operations-label"
                  id="operations"
                  name="operation"
                  value={this.state.operation}
                  onChange={event => this.setState({ operation: event.target.value })}
                >
                  <MenuItem value="add">Add Samples</MenuItem>
                  <MenuItem value="import">Import Samples</MenuItem>
                  <MenuItem value="align">Align Samples</MenuItem>
                  <MenuItem value="enrichment">Calculate Enrichments</MenuItem>
                  <MenuItem value="join">Export Sample Comparison Table</MenuItem>
                </Select>
              </FormControl>
              {this.state.operation == 'join' && (
                <Box className={classes.topMargin}>
                  <Typography variant="h6">Export Sample Comparison Table</Typography>
                  <Typography variant="body1">
                    Select samples that have been aligned to be exported as a CSV. The output will
                    be a joined table with the variant and its count and enrichment in each sample.
                    You can also set a count threshold, which is the minimum number of counts in any
                    of the selected samples for a variant to be included in the output. Note: if the
                    count threshold is very low ( &lt; 5), the output will be large and take a long
                    time to generate.
                  </Typography>
                  {this.state.sample_names != null ? (
                    <JoinForm
                      experiment_id={this.props.experiment_id}
                      samples={this.state.sample_names}
                    />
                  ) : (
                      <Loading />
                    )}
                </Box>
              )}

              {this.state.operation == 'add' && (
                <Box className={classes.topMargin}>
                  <Typography variant="h6">Add Samples to this Experiment</Typography>
                  <Typography variant="body1">
                    Add samples by providing the sample name, the i7 index and optionally the i5
                    index. You can copy and paste the table from Excel. Ensure that the ordering of
                    the columns is <code>sample_name, i7_index, i5_index</code> without headers.
                  </Typography>
                  <AddSamplesForm
                    experiment_id={this.props.experiment_id}
                    samples={this.state.sample_names}
                    callback={this.handleGetSampleNames}
                  />
                </Box>
              )}

              {this.state.operation == 'import' && (
                <Box className={classes.topMargin}>
                  <Typography variant="h6">Import Sequencing Files</Typography>
                  <Typography variant="body1">
                    Import sequencing files from a remote source using either HTTP(s), FTP, SFTP for
                    selected samples.
                  </Typography>
                  {this.state.sample_names != null ? (
                    <ImportSamplesForm
                      experiment_id={this.props.experiment_id}
                      samples={this.state.sample_names}
                      callback={this.handleGetSampleNames}
                    />
                  ) : (
                      <Loading />
                    )}
                </Box>
              )}
              {this.state.operation == 'align' && (
                <Box className={classes.topMargin}>
                  <Typography variant="h6">Align Samples</Typography>
                  <Typography variant="body1">
                    Align the sequencing files for each of the selected samples to generate a table
                    of variants.
                  </Typography>
                  {this.state.sample_names != null ? (
                    <AlignSamplesForm
                      experiment_id={this.props.experiment_id}
                      samples={this.state.sample_names}
                      callback={this.handleGetSampleNames}
                    />
                  ) : (
                      <Loading />
                    )}
                </Box>
              )}

              {this.state.operation == 'enrichment' && (
                <Box className={classes.topMargin}>
                  <Typography variant="h6">Calculate Enrichments</Typography>
                  <Typography variant="body1">
                    Calculate the enrichment for each aligned sample, given a starting library.
                  </Typography>
                  {this.state.sample_names != null ? (
                    <EnrichmentForm
                      experiment_id={this.props.experiment_id}
                      samples={this.state.sample_names}
                      callback={this.handleGetSampleNames}
                    />
                  ) : (
                      <Loading />
                    )}
                </Box>
              )}
            </Paper>
          </>
        )}
        <SampleTable
          tableRef={this.tableRef}
          experiment_id={this.props.experiment_id}
          handleImportSamplesOpen={this.handleImportSamplesOpen}
        />
      </>
    );
  }
}

const Experiment = withSnackbar(withStyles(styles.detail_styles)(ExperimentComponent));

export { Experiment };

