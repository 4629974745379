import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import { AminoAcidVariant } from './decorators/AminoAcidVariant';
import { NucleotideVariant } from './decorators/NucleotideVariant';
import { Number } from './decorators/Number';



const cell_padding = '8px 16px 8px 16px';

class VariantCountTableComponent extends React.Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.socketRef = React.createRef();
    this.columns = [
      {
        title: 'Link',
        field: 'variant_hash',
        sorting: false,
        filtering: false,
        cellStyle: { padding: cell_padding },
        render: obj => (
          <Button
            component={RouterLink}
            to={{
              pathname: `/variant/${obj.variant_hash}/`,
              state: {
                uuid: obj.variant_hash,
                nt: obj.variant,
                variant_type: this.props.variant_type.uuid
              }
            }}
            size="small"
            key={obj.variant_hash}
          >
            View
          </Button>
        )
      },
      {
        title: 'Variant',
        field: 'variant',
        sorting: false,
        cellStyle: { padding: cell_padding },
        render: obj => (
          <NucleotideVariant
            variant={obj.variant}
            variant_type={this.props.variant_type}
            reference={this.props.reference}
          />
        )
      },
      {
        title: 'Amino acid',
        field: 'aa',
        sorting: false,
        cellStyle: { padding: cell_padding },
        render: obj => (
          <AminoAcidVariant
            variant={obj.aa}
            variant_type={this.props.variant_type}
            reference={this.props.reference}
          />
        )
      },
      {
        title: 'Count',
        field: 'count',
        defaultSort: 'desc',
        type: 'numeric',
        cellStyle: { padding: cell_padding },
        render: obj => <Number num={obj.count} uncertainty={obj.count_uncertainty} digits={2} />
      },
      {
        title: 'Enrichment',
        field: 'enrichment',
        type: 'numeric',
        cellStyle: { padding: cell_padding },
        render: obj => <Number num={obj.enrichment} digits={4} />
      }
    ];
    this.fields = this.columns.map(a => a.field);
    this.fields.push('count_uncertainty');
    this.fields.push('enrichment_uncertainty');
    this.model_name = 'Variants';
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.topMargin}>
          <MaterialTable
            style={{
              justifyContent: 'center'
            }}
            tableRef={this.tableRef}
            columns={this.columns}
            data={query =>
              api.table_data_query(
                query,
                'variantcount',
                this.fields,
                this.props.sample_id,
                'sample',
                null
              )
            }
            options={{
              filtering: true,
              search: false,
              pageSize: 20,
              pageSizeOptions: [10, 20, 50]
            }}
            title="Variant Counts"
            actions={[
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange()
              },
              {
                icon: 'cloud_download',
                tooltip: 'Download CSV',
                isFreeAction: true,
                onClick: () => window.open(api.get_sample_dump_url(this.props.sample_id), '_parent')
              }
            ]}
            editable={{
              isEditable: rowData => false,
              isDeletable: rowData => false
            }}
          />
        </div>
      </>
    );
  }
}

const VariantCountTable = withSnackbar(
  withStyles(styles.detail_styles)(VariantCountTableComponent)
);
export { VariantCountTable };

