import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab/';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Form, Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';

class ExperimentForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.handleGetVariantTypes = this.handleGetVariantTypes.bind(this);
    var contentState = null;
    var blocksFromHTML = null;
    if (this.props.experiment.description != null) {
      blocksFromHTML = convertFromHTML(this.props.experiment.description);
      contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
    }
    this.state = {
      varianttypes: [],
      initialValues: {
        ...this.props.experiment,
        editorState:
          this.props.experiment.description == null
            ? new EditorState.createEmpty()
            : new EditorState.createWithContent(contentState)
      }
    };
  }

  async handleGetVariantTypes() {
    const query = { filters: [] };
    api.pack_filter(query, 'no_page', '');
    const varianttypes = await api.getList(
      'varianttype',
      query,
      ['name'],
      this.props.enqueueSnackbar
    );

    if (varianttypes == null) {
      return;
    }

    const vt = varianttypes.find(vt => vt.uuid == this.props.experiment.variant_type);
    this.setState({
      varianttypes: varianttypes,
      initialValues: { ...this.props.initialValues, variant_type: vt }
    });
  }

  async componentDidMount() {
    await this.handleGetVariantTypes();
  }

  async submitForm(values, { resetForm }) {
    const markup = draftToHtml(convertToRaw(values.editorState.getCurrentContent()));
    var payload = JSON.parse(JSON.stringify(values));
    payload.description = markup;
    payload.variant_type = values.variant_type.uuid;
    delete payload.editorState;
    if (this.props.mode == 'create') {
      await api.create('experiment', payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    } else {
      await api.update('experiment', payload, this.props.enqueueSnackbar);
      this.props.handleClose();
    }
    this.props.callback();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.initialValues != null && (
          <Formik initialValues={this.state.initialValues} onSubmit={this.submitForm}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleReset
              } = props;
              return (
                <>
                  <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={this.props.open}
                    onClose={this.props.handleClose}
                  >
                    <Form onSubmit={handleSubmit}>
                      <DialogTitle id="form-dialog-title">
                        {this.props.mode == 'create' ? 'Create' : 'Update'} Experiment
                      </DialogTitle>
                      <DialogContent>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.margin}
                            required
                            id="outlined-required"
                            label="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            margin="normal"
                            variant="outlined"
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <Autocomplete
                            className={classes.margin}
                            id="variant_type"
                            name="variant_type"
                            options={this.state.varianttypes}
                            getOptionLabel={option => option.name}
                            value={values.variant_type}
                            onChange={(_, value) => setFieldValue('variant_type', value)}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label="Variant Type"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel style={{ marginTop: '10px' }}>Description</FormLabel>
                          <Box style={{ marginTop: '10px' }}>
                            <Editor
                              editorState={values.editorState}
                              wrapperClassName="demo-wrapper"
                              editorClassName="demo-editor"
                              onEditorStateChange={editorState =>
                                setFieldValue('editorState', editorState)
                              }
                            />
                          </Box>
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button type="submit" disabled={isSubmitting} color="primary">
                          {this.props.mode == 'create' ? 'Create' : 'Update'}
                        </Button>
                      </DialogActions>
                    </Form>
                  </Dialog>
                </>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
}
ExperimentForm.defaultProps = {
  callback: Function.prototype
};

export default withSnackbar(withStyles(styles.form_styles)(ExperimentForm));
