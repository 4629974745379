import { Button, FormControl, Grid, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import TransferList from './TransferList';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class JoinForm extends React.Component {
  constructor(props) {
    super(props);
    this.aligned_samples = this.props.samples.filter(sample => sample.aligned);
    this.initialValues = {
      selected: [],
      samples: [],
      checked: [],
      choices_filter: '',
      chosen_filter: '',
      count_threshold: 10
    };
    this.submitForm = this.submitForm.bind(this);
  }

  async submitForm(values, { resetForm }) {
    await document.getElementById('JoinForm').submit();
    resetForm(this.initialValues);
  }

  render() {
    const { classes } = this.props;
    return (
      <Formik initialValues={this.initialValues} onSubmit={this.submitForm}>
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            setFieldValue,
            setTouched,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset
          } = props;
          return (
            <form
              id="JoinForm"
              action={api.get_join_dump_url(this.props.experiment_id)}
              method="POST"
            >
              {values.samples.map((value, index) => (
                <input type="hidden" name="samples[]" value={value.uuid} />
              ))}
              <TransferList
                loading={this.aligned_samples}
                options={this.aligned_samples}
                selected={values.samples}
                checked={values.checked}
                choices_filter={values.choices_filter}
                chosen_filter={values.chosen_filter}
                onSelectionChange={value => setFieldValue('samples', value)}
                onCheckedChange={value => setFieldValue('checked', value)}
                onChange={handleChange}
              />
              <FormControl className={classes.margin} fullWidth>
                <TextField
                  id="count_threshold"
                  name="count_threshold"
                  label="Count Threshold"
                  variant="outlined"
                  value={values.count_threshold}
                  onChange={handleChange}
                />
              </FormControl>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <Button
                    onClick={handleSubmit}
                    disabled={isSubmitting || values.samples.length == 0}
                    color="primary"
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default withSnackbar(withStyles(styles.form_styles)(JoinForm));
