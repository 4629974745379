import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as styles from '../util/style.jsx';


class RefSeqFormComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog onClose={this.props.handleClose} open={this.props.open}>
          <DialogTitle id="form-dialog-title">Reference Sequence</DialogTitle>
          <DialogContent>
            <DialogContentText>Create a new Reference Sequence</DialogContentText>
            <TextField
              label="Name"
              onChange={this.props.handleChange}
              value={this.props.refseq.name}
              fullWidth
              autoFocus
              name="name"
              margin="normal"
            />
            <TextField
              label="Sequence"
              onChange={this.props.handleChange}
              value={this.props.refseq.sequence}
              fullWidth
              autoFocus
              name="sequence"
              margin="normal"
              multiline
              rows={20}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={this.props.handleSubmit} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const RefSeqForm = withSnackbar(withStyles(styles.form_styles)(RefSeqFormComponent));

export { RefSeqForm };

