import * as auth from './auth.js';
/**
 * A configuration variable that states where the API for this application is
 * located.
 * TODO: relocate to configuration file.
 */
const prod = true;
const api_port = '443';
var hostname;
if (prod) {
  hostname = 'peat.caltech.edu';
} else {
  hostname = 'peat.caltech.edu';
}

export { hostname };
export const api_location = `https://${hostname}:${api_port}/api/v1`;

export const watcher_socket_location = `wss://${hostname}:${api_port}/ws/watch/`;

/**
 * A standard header for JSON POST requests
 */
export const JSONHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

/**
 * Generates the REST HTTP POST content, given payload. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_post_request(payload) {
  return {
    method: 'POST',
    headers: add_auth(JSONHeader),
    body: JSON.stringify(payload)
  };
}

/**
 * Generates the REST HTTP PATCH content, given payload. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_patch_request(payload) {
  return {
    method: 'PATCH',
    headers: add_auth(JSONHeader),
    body: JSON.stringify(payload)
  };
}

/**
 * Add authentication token to header, if it exists
 */
export function add_auth(header) {
  if (auth.isAuthenticated()) {
    // if the browser is authenticated, add it to the header
    const token = auth.getToken();

    header.Authorization = `Token ${token}`;
  }
  return header;
}

/**
 * Generates the REST HTTP GET request. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_get_request() {
  return {
    method: 'GET',
    headers: add_auth(JSONHeader)
  };
}

/**
 * Generates the REST HTTP DELETE request. Adds authorization
 * token to payload if present in Cookies
 */
export function gen_delete_request() {
  return {
    method: 'DELETE',
    headers: add_auth(JSONHeader)
  };
}

export function auth_decorate_url(url) {
  const token = auth.getToken();
  return url + '?auth_token=' + token;
}

export function notifyOrLog(msg, msgType, notify) {
  if (notify == null) {
    console.log(msg);
  } else {
    notify(msg, { variant: msgType });
  }
}

/**
 * Checks response for errors and raises an exception if the request failed
 * in some way.
 * @TODO redirect on "Unauthorized" (check HTTP resp code for unauthorized)
 */
export function handleErrors(response, notify) {
  if (!response.ok) {
    notifyOrLog(response.statusText, 'error', notify);
    return response.statusText;
  }
  return null;
}

/**
 * Checks response for errors and raises an exception if the request failed
 * in some way.
 */
export function handleErrorsThrow(response) {
  if (!response.ok) {
    throw response.statusText;
  }
}
