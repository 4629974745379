import { Box, Chip } from '@material-ui/core/';
import React from 'react';
import { DNA } from './DNA';

export function NucleotideVariant(props) {
  return (
    <Box display="flex" style={{ whiteSpace: 'nowrap' }}>
      <Chip
        variant="outlined"
        size="small"
        color="primary"
        label={props.reference.name + ':n' + props.variant_type.sites[0].start}
      />
      &nbsp;
      <DNA seq={props.variant} triplet={true} />
      &nbsp;
      <Chip
        variant="outlined"
        size="small"
        color="primary"
        label={props.reference.name + ':n' + (props.variant_type.sites[0].stop + 1)}
      />
    </Box>
  );
}
