import { Box, Container, Paper, Typography } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import '../../node_modules/highlight.js/styles/tomorrow.css';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';
import * as util from '../util/util.jsx';
import { DateTime } from './decorators/DateTime';
import { LargeSequence } from './decorators/LargeSequence';
import { DetailView } from './DetailView';
import { VariantTypeTable } from './VariantTypeTable';


/**
 * Component that renders a detailed view of the RefSeq
 */
class PureRefSeq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      errors: null,
      preview: null
    };
    this.model_name = 'RefSeq';
    this.model_rest = 'refseq';
    this.model_id = this.props.refseq_id;
    this.model_title = obj => obj.name;
    this.detail_fields = [
      {
        title: 'UUID',
        field: 'uuid'
      },
      {
        title: 'Name',
        field: 'name'
      },
      {
        title: 'Time created',
        field: 'time_created',
        render: obj => <DateTime timestamp={obj.time_created} />
      },
      {
        title: 'View Structure',
        render: obj => <RouterLink
          to={{
            pathname: `/pdbview`,
            state: {
              url: "rcsb://3ux1"
            }
          }}
        >AAV9</RouterLink>
      }
    ];
  }

  async componentDidMount() {
    await this.handleGetModel();
  }

  renderPreview() {
    const { classes } = this.props;
    return <></>;
  }

  async handleGetModel() {
    let model = await api.get(this.model_rest, this.model_id, this.props.enqueueSnackbar);
    if (model != null) {
      this.setState({
        model
      });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.model != null && (
          <Paper className={classes.root}>
            <DetailView
              model_name={this.model_name}
              model_title={this.model_title}
              detail_fields={this.detail_fields}
              model={this.state.model}
            />
          </Paper>
        )}
        <VariantTypeTable refseq_id={this.model_id} />
        <Paper className={classes.root}>
          {this.state.model != null && (
            <>
              <Typography variant="h6">Sequence</Typography>
              <Container className={classes.preview}>
                <Box fontFamily="Monospace">
                  <LargeSequence seq={this.state.model.sequence} />
                </Box>
              </Container>
            </>
          )}
        </Paper>
        <Paper className={classes.root}>
          {this.state.model != null && (
            <>
              <Typography variant="h6">Raw Nucleotide Sequence</Typography>
              <Container className={classes.preview}>
                <Box fontFamily="Monospace">
                  <code>{this.state.model.sequence}</code>
                </Box>
              </Container>
            </>
          )}
        </Paper>
        <Paper className={classes.root}>
          {this.state.model != null && (
            <>
              <Typography variant="h6">Raw Amino Acid Sequence</Typography>
              <Container className={classes.preview}>
                <Box fontFamily="Monospace">
                  <code>{util.translate(this.state.model.sequence)}</code>
                </Box>
              </Container>
            </>
          )}
        </Paper>
      </>
    );
  }
}

const RefSeq = withSnackbar(withStyles(styles.detail_styles)(PureRefSeq));

export { RefSeq };

