import { Avatar, Chip, Tooltip } from '@material-ui/core/';
import { blue, green, grey, red } from '@material-ui/core/colors/';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { DateTime } from './DateTime';

const status_styles = makeStyles(theme => ({
  RUNNING: {
    backgroundColor: green['500'],
    color: '#fff',
    margin: theme.spacing(0.5)
  },
  COMPLETED: {
    backgroundColor: blue['500'],
    color: '#fff',
    margin: theme.spacing(0.5)
  },
  ERROR: {
    backgroundColor: red['500'],
    color: '#fff',
    margin: theme.spacing(0.5)
  }
}));
export function Status(props) {
  const classes = status_styles();
  var status_icons = {
    RUNNING: {
      icon: <DirectionsRunIcon className={classes['RUNNING']} fontSize="small" />
    },
    COMPLETED: {
      icon: <CheckCircleIcon className={classes['COMPLETED']} fontSize="small" />
    },
    ERROR: {
      icon: <ErrorIcon className={classes['ERROR']} />
    },
    UNKNOWN: { icon: <React.Fragment></React.Fragment>, color: grey['500'] }
  };
  if (props.status && Object.keys(props.status).length > 0) {
    // sort keys in ascending order by the time at which the task started
    var sorted_keys = Object.keys(props.status)
      .sort(function (a, b) {
        return props.status[a].time_started - props.status[b].time_started;
      })
      .reverse();
    var to_render = sorted_keys;
    if (props.curated == true) {
      var running = sorted_keys.filter(key => props.status[key].status == 'RUNNING');
      var completed = sorted_keys.filter(key => props.status[key].status == 'COMPLETED');
      var errored = sorted_keys.filter(key => props.status[key].status == 'ERRORED');
      to_render = [].concat(running).concat(errored);
      // if no tasks are running, just show the most recently completed one.
      if (running.length == 0) {
        to_render.push(completed.pop());
      }
    }
    var chips = to_render.map(function (key, index) {
      var op = props.status[key];
      var status_icon =
        op.status in status_icons ? status_icons[op.status].icon : status_icons['UNKNOWN'].icon;
      var msg = op.general_msg + (op.status == 'RUNNING' ? ' (' + op.progress + '%)' : '');
      return (
        <Tooltip key={index} title={<DateTime timestamp={op.time_started} />}>
          <Chip
            size="small"
            avatar={<Avatar className={classes[op.status]}>{status_icon}</Avatar>}
            label={msg}
            className={classes[op.status]}
          />
        </Tooltip>
      );
    });
    return chips;
  }
  return <React.Fragment></React.Fragment>;
}
Status.defaultProps = {
  curated: true
};
