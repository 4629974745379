import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import { withSnackbar } from 'notistack';
import React from 'react';
import * as api from '../api/api.js';
import * as styles from '../util/style.jsx';

class VariantForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    // Only update updatable fields (uuid, name)
    this.state = {
      initialValues: (({ uuid, name }) => ({ uuid, name }))(this.props.variant)
    };
  }

  async submitForm(values, { resetForm }) {
    var payload = JSON.parse(JSON.stringify(values));
    await api.update('variant', payload, this.props.enqueueSnackbar);
    this.props.handleClose();
    this.props.callback();
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        {this.state.initialValues != null && (
          <Formik initialValues={this.state.initialValues} onSubmit={this.submitForm}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                handleReset
              } = props;
              return (
                <>
                  <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    open={this.props.open}
                    onClose={this.props.handleClose}
                  >
                    <Form onSubmit={handleSubmit}>
                      <DialogTitle id="form-dialog-title">Update Variant</DialogTitle>
                      <DialogContent>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.margin}
                            required
                            id="outlined-required"
                            label="Name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            margin="normal"
                            variant="outlined"
                          />
                        </FormControl>
                      </DialogContent>
                      <DialogActions>
                        <Button type="submit" disabled={isSubmitting} color="primary">
                          Update
                        </Button>
                      </DialogActions>
                    </Form>
                  </Dialog>
                </>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
}

VariantForm.defaultProps = {
  callback: Function.prototype
};
export default withSnackbar(withStyles(styles.form_styles)(VariantForm));
