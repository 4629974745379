import { Container, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core/';
import React from 'react';
import Loading from './Loading';

export function DetailView(props) {
  if (props.model == null) {
    return <Loading />;
  }
  let elems = [];
  props.detail_fields.forEach(function (val, idx) {
    if (val.hasOwnProperty('render')) {
      var renderedValue = val.render(props.model);
    } else {
      var renderedValue = props.model[val.field];
    }
    elems.push(
      <TableRow key={val.title}>
        <TableCell>
          <b>{val.title}:</b>
        </TableCell>
        <TableCell>{renderedValue}</TableCell>
      </TableRow>
    );
  });
  return (
    <Container>
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h4">
            {props.model_name}: {props.model_title(props.model)}
          </Typography>
        </Grid>
        {}
        <Grid item>{props.edit}</Grid>
      </Grid>
      <Table>
        <TableBody>{elems}</TableBody>
      </Table>
    </Container>
  );
}

DetailView.defaultProps = {
  edit: <></>
};
