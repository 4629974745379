import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@material-ui/core/';
import React from 'react';
import { ForceGraph2D, ForceGraph3D, ForceGraphVR } from 'react-force-graph';
import { SizeMe } from 'react-sizeme';
import SpriteText from 'three-spritetext';

/**
 * Generates a histogram plot from the histogram data, along with the bins
 * associated with the values.
 */
class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graph: { nodes: [], links: [] },
      enable: false,
      mode: '2d'
    };
  }

  componentDidMount() {
    const graph_copy = JSON.parse(JSON.stringify(this.props.graph));
    var node_freq = {};
    graph_copy.links.forEach(elem => {
      node_freq[elem.source] = ++node_freq[elem.source] || 1;
      node_freq[elem.target] = ++node_freq[elem.target] || 1;
    });

    const nodes = graph_copy.nodes.filter(elem => elem.id in node_freq);
    const links = graph_copy.links.filter(
      elem => elem.source in node_freq && elem.target in node_freq
    );

    this.setState({
      graph: { nodes: nodes, links: links }
    });
  }

  componentWillUnmount() {
    this.setState({
      graph: { nodes: [], links: [] },
      enable: false
    });
  }

  getGraphComponent(size) {
    if (this.state.enable == true) {
      if (this.state.mode == 'vr') {
        return (
          <Container style={{ marginTop: '50px' }}>
            <ForceGraphVR
              key="vr_key"
              graphData={this.state.graph}
              width={size.width}
              height={500}
              nodeColor={d => (d.enrichment > 0 ? '#00ff00' : '#ff0000')}
              nodeVal={d => 4 * Math.abs(Math.pow(10, d.enrichment))}
              linkWidth={5}
              nodeThreeObject={node => {
                const sprite = new SpriteText(node.id);
                sprite.color = node.enrichment > 0 ? '#00ff00' : '#ff0000';
                sprite.textHeight = 14;
                return sprite;
              }}
            />
          </Container>
        );
      } else if (this.state.mode == '2d') {
        return (
          <Box style={{ marginTop: '20px' }}>
            <ForceGraph2D
              graphData={this.state.graph}
              key="2d_key"
              width={size.width}
              height={500}
              nodeColor={d => (d.enrichment > 0 ? '#1B5E20' : '#B71C1C')}
              nodeVal={d => Math.abs(Math.pow(d.enrichment, 2))}
              nodeLabel={d => d.id}
              linkWidth={2}
              backgroundColor="#eee"
              linkColor="#000"
              linkOpacity={1.0}
              nodeCanvasObject={(node, ctx, globalScale) => {
                const label = node.id;
                const fontSize = (24 * node.enrichment) / globalScale;
                ctx.font = `${fontSize}px Sans-Serif`;
                const textWidth = ctx.measureText(label).width;
                const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

                ctx.fillStyle = 'rgba(255, 255, 255, 1.0)';
                ctx.fillRect(
                  node.x - bckgDimensions[0] / 2,
                  node.y - bckgDimensions[1] / 2,
                  ...bckgDimensions
                );

                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillStyle =
                  node.enrichment > 0 ? 'rgba(0, 255, 0, 0.8)' : 'rgba(255, 0, 0, 0.8)';
                ctx.fillText(label, node.x, node.y);
              }}
            />
          </Box>
        );
      } else if (this.state.mode == '3d') {
        return (
          <Container style={{ marginTop: '20px' }}>
            <ForceGraph3D
              graphData={this.state.graph}
              key="3d_key"
              linkWidth={3}
              width={size.width}
              height={500}
              nodeColor={d => (d.enrichment > 0 ? '#00ff00' : '#ff0000')}
              nodeVal={d => Math.abs(Math.pow(10, d.enrichment))}
              nodeLabel={d => d.id}
            />
          </Container>
        );
      }
    } else {
      return <></>;
    }
  }

  render() {
    if (this.props.graph == {} || this.props.graph.hasOwnProperty('node')) {
      return <></>;
    }
    return (
      <Paper className={this.props.className}>
        <Typography variant="h6">{this.props.title}</Typography>
        <Grid container spacing={1} direction="row" justify="center" alignItems="flex-end">
          <Grid item sm={11}>
            <FormControl fullWidth>
              <InputLabel id="operations-label">View mode</InputLabel>
              <Select
                labelId="operations-label"
                id="operations"
                name="operation"
                value={this.state.mode}
                onChange={event => this.setState({ mode: event.target.value })}
                margin="normal"
              >
                <MenuItem value="2d">2D Graph</MenuItem>
                <MenuItem value="3d">3D Graph</MenuItem>
                <MenuItem value="vr">Virtual Reality Graph</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={1}>
            <Button
              variant="contained"
              onClick={() => this.setState({ enable: !this.state.enable })}
            >
              {this.state.enable ? 'Hide' : 'Show'}
            </Button>
          </Grid>
        </Grid>
        <a-entity daydream-controls></a-entity>
        <SizeMe>{({ size }) => this.getGraphComponent(size)}</SizeMe>
      </Paper>
    );
  }
}

export default Graph;

/**
 *
          {props.graph.nodes.map(n => (
            <ForceGraphNode key={n.id + '_node'} node={n} fill="red" />
          ))}
          {props.graph.links.map(lnk => (
            <ForceGraphLink key={lnk.source + '_' + lnk.target} link={lnk} />
          ))}
 */
